<template>
  <CategoriesList/>
  <ActualOffers :actualOffers="actualOffers" @changeOfferStatus="changeOfferStatus"></ActualOffers>
  <GetCatalog/>
  <brands-line :brands="brands"/>
  <sales-list/>
  <about-company/>
  <realised-works/>
  <Users-feedbacks/>
  <Contacts/>
</template>

<script>

import Header from "@/components/Layout/Header";
import CategoriesList from "@/components/Layout/CategoriesList";
import ActualOffers from "@/components/Layout/ActualOffers";
import GetCatalog from "@/components/Layout/GetCatalog";
import BrandsLine from "@/components/Layout/BrandsLine";
import SalesList from "@/components/Layout/SalesList";
import AboutCompany from "@/components/Layout/AboutCompany";
import RealisedWorks from "@/components/Layout/RealisedWorks";
import UsersFeedbacks from "@/components/Layout/UsersFeedbacks";
import Contacts from "@/components/Layout/Contacts";
import Footer from "@/components/Layout/Footer";
import axios from "axios";
import { useMeta } from 'vue-meta'

export default {
  components: {
    BrandsLine,
    Header,
    CategoriesList,
    ActualOffers,
    GetCatalog,
    SalesList,
    AboutCompany,
    RealisedWorks,
    UsersFeedbacks,
    Contacts,
    Footer
  },
  setup () {
    useMeta({
      title: 'Топтыгинпол - напольные покрытия в Казани по выгодной цене - ТОПТЫГИНПОЛ Казань',
      description: 'Топтыгинпол - магазин напольных покрытий в Казани! Огромный выбор ламината, линолеума, кварцвинила. Наш адрес: г. Казань, Габишева 2. Тел: 8 (987) 422-59-94',
    })
  },
  data() {
    return {
      actualOffers: [],
      brands: [
        {image: 'img/brands/x1.svg', link: '/search?s=aspenfloor'},
        {image: 'img/brands/x2.svg', link: '/search?s=aroma'},
        {image: 'img/brands/x3.svg', link: '/search?s=alpine%20floor'},
        {image: 'img/brands/x4.svg', link: '/search?s=quick%20step'},
        {image: 'img/brands/x5.svg', link: '/search?s=boho'},
        {image: 'img/brands/x6.svg', link: '/search?s=tarkett'},
        {image: 'img/brands/x7.svg', link: '/search?s=cm floor'},
        {image: 'img/brands/x8.svg', link: '/search?s=planker'},
        {image: 'img/brands/x9.svg', link: '/search?s=art east'},
        {image: 'img/brands/x10.svg', link: '/search?s=agt'},
        {image: 'img/brands/x11.svg', link: '/search?s=clix'},

      ],
    }
  },
  methods: {
    changeOfferStatus(newOffer) {
      this.actualOffers.forEach(function (oldOffer) {
        oldOffer.isActive = newOffer.name === oldOffer.name;
      });
    },
    async getActualOffers() {
      try {
        const response = await axios.post(
            this.$store.state.params.apiURL,
            {
              action: 'get-actual-offers',
            }
        );
        this.actualOffers = response.data;
      }catch (e) {
        console.log(e)
      }
    }
  },
  mounted() {
    this.getActualOffers();
    console.log(this.$store.state.catalog.catalogFilters);
  }
}
</script>

<style>

</style>