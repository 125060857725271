<template>
  <a class="showmo"><slot></slot></a>
</template>

<script>
export default {
  name: 'showmo'
}
</script>

<style scoped>
.showmo{
  font-size: 18px;
  border-bottom: 2px solid #fff;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s;
  display: inline;
}
.showmo.danger{
  border-color: #DD204F;
  color: #DD204F;
}
.showmo.dark{
  color: #3A3A3A;
  border-color: #3A3A3A;
}
.showmo.default{
  color: #A5A5A5;
  border-color: #A5A5A5;
}
.showmo.sm{
  font-size: 16px;
}
.showmo.bold{
  font-weight: 600;
}
.showmo:hover{
  border-color: transparent;
}

@media(max-width: 575.98px) {
  .showmo{
    font-size: 16px;
  }
}
</style>