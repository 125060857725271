<template>
  <section class="works">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-md-7">
          <div class="main-title">Реализованные работы</div>
          <text-lg>За <b>10 лет</b> «Топтыгинпол» помог более <b>6000 клиентам</b> подобрать и установить качественные напольные покрытия</text-lg>
        </div>
      </div>
      <div class="row">
        <div v-for="(work, i) in workItems" :class="classes[i]">
          <div :href="work.link" class="work-item" :style="{'background-image': 'url('+work.image+')'}"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      classes: [
        'col-md-3 col-6',
        'col-md-6 col-6',
        'col-md-3 col-12',
        'col-md-3 col-6',
        'col-md-3 col-6',
        'col-md-6 col-12',
        'col-md-6 col-6',
        'col-md-3 col-6',
        'col-md-3 col-12',
        'col-md-3 col-6',
        'col-md-6 col-6',
        'col-md-3 col-12',
        'col-md-3 col-6',
        'col-md-3 col-6',
        'col-md-6 col-12',
        'col-md-6 col-6',
        'col-md-3 col-6',
        'col-md-3 col-12',
      ],
      workItems: [
        {link: '#', image: 'img/works/x1.jpg'},
        {link: '#', image: 'img/works/x2.jpg'},
        {link: '#', image: 'img/works/x3.jpg'},
        {link: '#', image: 'img/works/x4.jpg'},
        {link: '#', image: 'img/works/x5.jpg'},
        {link: '#', image: 'img/works/x6.jpg'},
        {link: '#', image: 'img/works/x7.jpg'},
        {link: '#', image: 'img/works/x8.jpg'},
        {link: '#', image: 'img/works/x9.jpg'},
        {link: '#', image: 'img/works/x10.jpg'},
        {link: '#', image: 'img/works/x11.jpg'},
        {link: '#', image: 'img/works/x12.jpg'},
        {link: '#', image: 'img/works/x13.jpg'},
        {link: '#', image: 'img/works/x14.jpg'},
        {link: '#', image: 'img/works/x15.jpg'},
        {link: '#', image: 'img/works/x16.jpg'},
        {link: '#', image: 'img/works/x17.jpg'},
        {link: '#', image: 'img/works/x18.jpg'},
      ]
    }
  },


}
</script>

<style scoped>
.works .main-title{
  margin-bottom: 40px;
}
.works .text-lg{
  margin-bottom: 60px;
  text-align: center;
}
.work-item{
  display: block;
  height: 277px;
  border-radius: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.5s;
  margin-bottom: 30px;
}
.work-item:hover{
  opacity: 0.7;
}

@media (max-width: 575.98px) {
  .work-item{
    height: 180px;
    margin-bottom: 15px;
  }
}
</style>