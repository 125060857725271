<template>
  <section>
    <div class="container mt-5 mb-5">
      <div class="row">
        <div class="col-md-4 offset-md-4 mt-5 mb-5">
          <form @submit.prevent="sendForm">
            <div class="main-title">Авторизация</div>
            <v-input-large placeholder="Логин" id="login-input" class="mb-3"/>
            <v-input-large placeholder="Пароль" type="password" id="password-input" class="mb-3"/>
            <div class="d-grid">
              <v-button class="btn-lg">Войти</v-button>
            </div>
            <div class="error text-danger text-center mt-3" id="error-block"></div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VInputLarge from "@/components/UI/VInputLarge";
import VButton from "@/components/UI/VButton";
export default {
  components: {VButton, VInputLarge},
  methods: {
    sendForm(event) {
      let isValid = true;
      const form = event.target;
      const fields = {
        login: {
          block: form.querySelector('#login-input'),
          successValue: 'renat-admin',
          errorText: 'Неверный логин'
        },
        password: {
          block: form.querySelector('#password-input'),
          successValue: 'VA31i9DP1gyD',
          errorText: 'Неверный пароль'
        },
      }
      const errorBlock = form.querySelector('#error-block');
      errorBlock.innerHTML = '';



      Object.keys(fields).every((key) => {
        console.log(key);
        if(fields[key].block.value !== fields[key].successValue) {
          fields[key].block.focus()
          errorBlock.innerHTML = fields[key].errorText;
          isValid = false
          return false
        }
        return true
      })

      if(isValid) {
        console.log('Авторизация')
        this.$store.commit('setAuth');
        location.reload()
      }

    }
  },
  mounted() {
    if(this.$store.getters.isAuth) this.$router.push('/');
  }
}
</script>

<style scoped>

</style>