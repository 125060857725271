<template>
  <section class="calc-wrap">
    <div class="container">
      <div class="main-title">Калькулятор</div>
<!--      <div class="text-lg text-center mb-5">Введите параметры помещения чтобы получить расчёт</div>-->
      <div class="calc-block">
        <div class="d-md-flex justify-content-between d-block mb-3">
          <div class="form-group">
            <div class="name">Кол-во упаковок</div>
            <div class="calc-input-wrap">
              <calc-input v-model:value="calcValues.packs" :objKey="'packs'" @updateCalcInput="updateCalcInput"/>
            </div>
          </div>
          <div class="btwn">или</div>
          <div class="form-group">
            <div class="name">Введите площадь (м²)</div>
            <div class="calc-input-wrap">
              <calc-input v-model:value="calcValues.totalSquare" :objKey="'totalSquare'" @updateCalcInput="updateCalcInput"/>
            </div>
          </div>
          <div class="btwn">=</div>
          <div class="form-group">
            <div class="name">Итого</div>
            <div class="calc-input-wrap total">
              <v-input-large :value="calcValues.totalPrice+'₽'" disabled=""/>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center" v-if="checkBoxes.length > 0">
          <div v-for="checkbox in checkBoxes">
            <check-box :checkbox="checkbox" @changeCheckbox="changeCheckbox"/>
          </div>

        </div>
        <div class="divider"></div>
        <div class="calc-results">
          <div class="calc-item">
            <div class="name">Площадь упаковки</div>
            <div class="value">{{calcValues.packSquare}}м²</div>
          </div>
          <div class="calc-item">
            <div class="name">Количество упаковок</div>
            <div class="value">{{calcValues.packs}}шт</div>
          </div>
          <div class="calc-item">
            <div class="name">Суммарная площадь</div>
            <div class="value">{{ this.calcValues.totalSquare }}м²</div>
          </div>

          <div class="calc-item">
            <div class="name">Суммарная стоимость</div>
            <div class="value">{{calcValues.totalPrice}}₽</div>
          </div>
          <div class="calc-actions">
            <v-button class="btn-danger btn-lg mb-2" v-if="!$store.getters.isInBasket(product.id)" @click="$emit('addToBasket')">Добавить в корзину</v-button>
            <v-button class="btn-lg mb-2" v-else  @click="$store.commit('removeFromBasket', product.id)">Убрать из корзины</v-button>
            <div>
              <showmo class="dark">Получить консультацию</showmo>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CalcInput from "@/components/UI/CalcInput";
import VInputLarge from "@/components/UI/VInputLarge";
import CheckBox from "@/components/UI/CheckBox";
export default {
  components: {CheckBox, VInputLarge, CalcInput},
  props: {
    calcValues: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      checkBoxes: [
        // {
        //   text: 'Укладка по прямой (+5%)',
        //   class: 'radio',
        //   stock: 5,
        //   isActive: true
        // },
        // {
        //   text: 'Укладка диагонали (+10%)',
        //   class: 'radio',
        //   stock: 10,
        //   isActive: false
        // },
        // {
        //   text: 'Учитывать запас',
        //   class: '',
        //   stock: 0,
        //   isActive: false
        // },
      ],
    }
  },
  methods: {
    updateCalcInput(event, key) {
      this.$emit('updateCalcInput', event, key);
    },
    changeCheckbox(checkbox){
        this.checkBoxes.forEach(function (newCheckbox) {
          if(checkbox.class === 'radio'){
            if(newCheckbox.class === 'radio') newCheckbox.isActive = false;
            if(checkbox === newCheckbox) newCheckbox.isActive = true;
          }else{
            if(checkbox === newCheckbox) newCheckbox.isActive = !newCheckbox.isActive;
          }
        });


    }
  },
  watch: {
    checkBoxes: {
      handler(newVal) {
        let stock = 0;
        let isStock = false;
        newVal.forEach(function (elem) {
          if(elem.class === 'radio' && elem.isActive === true) stock = elem.stock;
          else if(elem.class === '' && elem.isActive === true) isStock = true;
        })

        if(!isStock) stock = 0;
        this.$emit('changeStock', stock);
      },
      deep: true
    }
  }

}
</script>

<style scoped>
.calc-wrap{
  padding-top: 0;
  margin-top: 0;
}
.calc-block{
  padding: 45px 130px;
  border-radius: 20px;
  background-color: #F5F5F5;
}
.calc-block .calc-input-wrap{
  max-width: 250px;
}
.calc-block .btwn{
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.calc-block .divider{
  height: 1px;
  background-color: #D9D9D9;
  margin-top: 30px;
  margin-bottom: 15px;
}
.calc-block .calc-item{
  max-width: 123px;
}
.calc-block .calc-item .name{
  margin-bottom: 10px;
}
.calc-block .form-group{
  position: relative;
}
.calc-block .form-group .name{
  text-align: center;
  font-size: 16px;
  color: #A5A5A5;
  position: absolute;
  top: -30px;
  left: 15px;

}
.calc-block .calc-item .value{
  font-size: 24px;
  font-weight: 700;
}

.calc-block .total .form-control{
  text-align: center;
}
.calc-block .calc-results{
  display: flex;
  justify-content: space-between;
}
.calc-block .calc-actions{
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media(max-width: 575.98px) {
  .calc-wrap{
    margin-top: 30px;
  }
  .calc-block{
    padding: 40px 15px;
  }
  .calc-block .form-group{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .calc-block .form-group .name{
    position: relative;
    top: auto;
    left: auto;
    margin-bottom: 5px;
  }
  .calc-block .btwn{
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .calc-block .calc-results{
    flex-wrap: wrap;
  }
  .calc-block .calc-item{
    width: 45%;
    max-width: 300px;
    margin-bottom: 20px;
  }
  .calc-block .calc-item .name{
    text-align: center;
    margin: 0 auto 5px;
    max-width: 128px;

  }
  .calc-block .calc-item .value{
    text-align: center;
  }
  .calc-block .calc-actions{
    margin: 15px auto 0;
  }
}
</style>