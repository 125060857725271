<template>
  <section>
    <div class="container">
      <div class="main-title">Контакты</div>
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="contact-item">
            <div class="param">Телефон</div>
            <a :href="contacts.phone_link_1" class="value">{{ contacts.phone_1 }}</a>
            <a :href="contacts.phone_link_1" class="value">{{ contacts.phone_2 }}</a>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="contact-item">
            <div class="param">Соц. сети</div>
            <div class="social">
              <a :href="contacts.instagram_link" rel="nofollow" target="_blank" class="soc-item inst"></a>
              <a :href="contacts.whatsapp_link" rel="nofollow" target="_blank" class="soc-item whatsapp"></a>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="contact-item">
            <div class="param">Email</div>
            <a :href="'mailto:' + contacts.email" class="value">{{ contacts.email }}</a>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="contact-item">
            <div class="param">Режим работы</div>
            <div class="value">{{ contacts.worktime_1 }} <br>{{ contacts.worktime_2 }}</div>
          </div>
        </div>
        <div class="col-md-6 col-md-6">
          <div class="contact-item">
            <div class="param">Адрес 1</div>
            <a :href="contacts.address_link_1" rel="nofollow" target="_blank" class="value">{{ contacts.address_full_1 }}</a>
            <iframe :src="contacts.iframe_1" width="560" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe>
          </div>
        </div>
        <div class="col-md-6 col-md-6">
          <div class="contact-item">
            <div class="param">Адрес 2</div>
            <a :href="contacts.address_link_2" rel="nofollow" target="_blank" class="value">{{ contacts.address_full_2 }}</a>
            <iframe :src="contacts.iframe_2" width="560" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      contacts: this.$store.state.contacts
    }
  }
}
</script>

<style scoped>
.contact-item{
  margin-bottom: 45px;
  display: flex;
  flex-direction: column;
}
.contact-item .param{
  font-weight: 600;
  font-size: 18px;
  color: #A5A5A5;
  margin-bottom: 10px;
}
.contact-item .value{
  text-decoration: none;
  color: #504545;
  font-weight: 600;
  font-size: 22px;
  transition: 0.3s;
}
.contact-item a.value:hover{
  color: #DD204F;
}
.contact-item iframe{
  border-radius: 20px;
  margin-top: 30px;
  width: 100%;
  height: 380px;
}
.social{
  display: flex;
}
.social .soc-item{
  height: 45px;
  width: 75px;
  border-radius: 10px;
  background-size: 30px;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.social .soc-item.vk{
  background-color: #039BE5;
  background-image: url("@/assets/img/vk.png");
}
.social .soc-item.inst{
  background-color: #E75B6C;
  background-image: url("@/assets/img/inst.png");
}
.social .soc-item.whatsapp{
  background-color: #82BF00;
  background-image: url("@/assets/img/whatsapp.png");
}

@media (max-width: 575.98px) {
  .contact-item{
    margin-bottom: 25px;
  }
  .contact-item .param{
    text-align: center;
  }
  .contact-item .value{
    text-align: center;
  }
  .social{
    justify-content: center;
  }
  .social .soc-item{
    margin-left: 10px;
  }
}
</style>