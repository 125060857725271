<template>
  <section>
    <div class="container">
      <div class="main-title">
        Поиск по запросу: <br>
        «{{ this.$route.query.s }}»
      </div>
      <div v-if="isLoading" class="d-flex justify-content-center">
        <div class="spinner-grow text-danger m-2" role="status"></div>
        <div class="spinner-grow text-danger m-2" role="status"></div>
        <div class="spinner-grow text-danger m-2" role="status"></div>
      </div>
      <div class="row" v-if="products.length > 0">
        <div class="col-md-3 col-6" v-for="product in products">
          <product-item :key="product.id" :product="product" @removeProduct="removeProduct" @changeProduct="changeProduct"/>
        </div>
        <div class="col-12 d-flex justify-content-center mt-5">
          <navigation
              :total-pages="Math.ceil(productsCount/pageLimit)"
              :total="productsCount"
              :per-page="pageLimit"
              :current-page="currentPage"
              @pagechanged="onPageChange"
          />
        </div>
      </div>
    </div>
  </section>
  <product-modal :product="editableProduct" v-model:show="productModal"/>
</template>

<script>
import axios from "axios";
import ProductItem from "@/components/Layout/ProductItem";
import Navigation from "@/components/UI/Navigation";
import ProductModal from "@/components/Admin/ProductModal";

export default {
  components: {ProductItem, Navigation, ProductModal},
  data() {
    return {
      products: [],
      isLoading: true,
      currentPage: 1,
      pageLimit: 32,
      productsCount: 0,
      productModal: false,
      editableProduct: []
    }
  },
  methods: {
    async searchProducts(){
      try {
        const response = await axios.post(
            this.$store.state.params.apiURL,
            {
              action: 'search-products',
              query: this.$route.query.s,
              page: this.currentPage,
              limit: this.pageLimit,
            }
        );
        this.products = [...response.data.products];
        this.productsCount = response.data.productsCount;
      } catch (e) {
        alert('Ошибка');
      } finally {
        this.isLoading = false;
      }
    },
    onPageChange(page) {
      this.currentPage = page;
      this.searchProducts(this.$route.query.s);
      window.scrollTo(0,0);
    },
    changeProduct(product) {
      this.editableProduct = product;
      this.productModal = true;
      console.log(this.editableProduct);
    },
    removeProduct(id) {
      if(!confirm('Действительно хотите удалить?')) return;

      this.$store.commit('removeProduct', {
        id: id,
        products: this.products,
      });
    },
  },
  mounted() {
    if(!this.$route.query.s) return this.$router.push('/');
    this.searchProducts(this.$route.query.s);
  },
  watch: {
    $route (to, from){
      this.searchProducts(this.$route.query.s);
    }
  }
}
</script>

<style scoped>

</style>