<template>
<!--  <div class="sale-line">-->
<!--    <div class="sl-container">-->
<!--      <div class="all-sl">-->
<!--        <div class="sl-item">-10% на весь каталог</div>-->
<!--        <div class="sl-item">-10% на весь каталог</div>-->
<!--        <div class="sl-item">-10% на весь каталог</div>-->
<!--        <div class="sl-item">-10% на весь каталог</div>-->
<!--        <div class="sl-item">-10% на весь каталог</div>-->
<!--      </div>-->
<!--      <div class="all-sl">-->
<!--        <div class="sl-item">-10% на весь каталог</div>-->
<!--        <div class="sl-item">-10% на весь каталог</div>-->
<!--        <div class="sl-item">-10% на весь каталог</div>-->
<!--        <div class="sl-item">-10% на весь каталог</div>-->
<!--        <div class="sl-item">-10% на весь каталог</div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
  <section>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <bread-crumbs
              :crumbs="[
                  {href: '/', name: 'Главная'},
                  {href: '/catalog', name: 'Каталог'},
                  {href: this.$route.params.type, name: getCatalogSection(this.$route.params.type).name, isCurrent: true}
              ]" />
          <h1 class="main-title left">{{ getCatalogSection(this.$route.params.type).name }}</h1>
        </div>
        <div class="col-md-6 d-flex flex-column align-items-end">
          <div class="up-sort">Сортировка</div>
          <dropdown-button class="sort-button mb-md-0 mb-2" @changeDropdownItem="changeDropdownItem" :dropdownItems="productsSort"/>
          <div class="d-grid d-md-none w-100 mb-3">
            <v-button class="btn-danger" @click="showFilters = !showFilters">Показать фильтры</v-button>
          </div>
          <v-button v-if="$store.getters.isAuth" class="btn-danger mt-3" @click="addProduct">Добавить товар</v-button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <catalog-filters
              v-if="showFilters"
              :filters="filters"
              @checkFilterItem="checkFilterItem"
              @changeFilterStatus="changeFilterStatus"
              @resetFilterParams="resetFilterParams"
              @showFilterResults="showFilterResults"
          />
        </div>
        <div class="col-md-9">
          <div v-if="isProductsLoading" class="d-flex justify-content-center">
            <div class="spinner-grow text-danger m-2" role="status"></div>
            <div class="spinner-grow text-danger m-2" role="status"></div>
            <div class="spinner-grow text-danger m-2" role="status"></div>
          </div>
          <div v-else>
            <div class="row" v-if="products.length > 0">
              <div class="col-md-4 col-6" v-for="product in products">
                <product-item :product="product" :key="product.id" @removeProduct="removeProduct" @changeProduct="changeProduct"/>
              </div>
              <div class="col-12 d-flex justify-content-center mt-5">
                <navigation
                    :total-pages="Math.ceil(productsCount/pageLimit)"
                    :total="productsCount"
                    :per-page="pageLimit"
                    :current-page="currentPage"
                    @pagechanged="onPageChange"
                />
                <v-button v-if="productsFrom < productsCount" @click="showMoreProducts" class="btn-danger btn-lg">Показать ещё</v-button>
                <div ref="observer" class="observer"></div>
              </div>
            </div>
            <not-found v-else>Ничего не найдено</not-found>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Contacts/>
  <product-modal :product="editableProduct" v-model:show="productModal"/>
</template>

<script>
import axios from 'axios';
import ProductItem from "@/components/Layout/ProductItem";
import NotFound from "@/components/UI/NotFoundNotice";
import CatalogFilters from "@/components/Layout/CatalogFilters";
import Navigation from "@/components/UI/Navigation";
import VButton from "@/components/UI/VButton";
import BreadCrumbs from "@/components/UI/BreadCrumbs";
import Contacts from "@/components/Layout/Contacts";
import ProductModal from "@/components/Admin/ProductModal";
import {useMeta} from "vue-meta";
export default {
  components: {
    BreadCrumbs,
    VButton,
    Navigation,
    CatalogFilters,
    NotFound,
    ProductItem,
    Contacts,
    ProductModal
  },
  data () {
    return {
      currentPage: this.$store.state.catalog.currentPage,
      pageLimit: 30,
      productsCount: 0,
      products: [],
      isProductsLoading: true,
      productsSort: [
        {name: 'По умолчанию', isActive: true, value: 'ORDER BY id DESC'},
        {name: 'Цена по возрастанию', isActive: false, value: 'ORDER BY price ASC'},
        {name: 'Цена по убыванию', isActive: false, value: 'ORDER BY price DESC'},
      ],
      activeSort: this.$store.state.catalog.activeSort,
      filters: this.$store.state.catalog.catalogFilters,
      showFilters: true,
      productModal: false,
      editableProduct: []
    }
  },
  methods: {
    async fetchProducts(append = false) {
      try {
        if(!append) this.isProductsLoading = true;
        const response = await axios.post(
            this.$store.state.params.apiURL,
            {
              type: this.getCatalogSection(this.$route.params.type).type,
              action: 'get-catalog',
              page: this.currentPage,
              limit: this.pageLimit,
              sort: this.activeSort,
              filters: this.filters
            }
        );
        console.log(response.data);
        if(append) this.products = [...this.products, ...response.data.products];
        else this.products = response.data.products;
        this.productsCount = response.data.productsCount;
        console.log(this.products);
      } catch (e) {
        alert('Ошибка');
      } finally {
        this.isProductsLoading = false;
      }
    },
    async fetchFilters() {
      try {
        const response = await axios.post(
            this.$store.state.params.apiURL,
            {
              action: 'get-filters',
              type: this.getCatalogSection(this.$route.params.type).type
            }
        );
        this.filters = response.data;
        this.filters.forEach(function (filter) {
          filter.values.sort(function (a, b) {
            if(a.value > b.value) return 1;
            if(a.value < b.value) return -1;
            return 0;
          });
        });
      } catch (e) {
        alert('Ошибка');
      } finally {
        this.isProductsLoading = false;
      }
    },
    changeDropdownItem(item){
      this.productsSort.forEach((sortItem) => sortItem.isActive = sortItem === item);
      this.activeSort = item.value;
    },
    showMoreProducts(){
      this.productsFrom += this.productsLimit;
      this.fetchProducts(true);
    },
    checkFilterItem(param_id, value) {
      this.filters.forEach(function (filter) {
        if(filter.id === param_id) {
          filter.values.forEach(function (filterValue) {
            if(filterValue === value) filterValue.isActive = !filterValue.isActive;
          });
        }
      });
      this.currentPage = 1;
      this.fetchProducts();
    },
    changeFilterStatus(param_id) {
      let param = this.filters.find(param => param.id === param_id);
      if(param) param.isActive = !param.isActive;
    },
    resetFilterParams() {
      this.filters.forEach(function (filter) {
        filter.values.forEach((value) => value.isActive = false);
      });
      this.fetchProducts();
      window.scrollTo(0, 0);
      if(window.screen.width <=768) {
        this.showFilters = false;
      }
    },
    getCatalogSection(link) {
      switch (link){
        case 'laminate': return {type: 1, name: 'Ламинат', seoTitle: 'Купить ламинат — более 200 видов  - ТОПТЫГИНПОЛ Казань', seoDescription: 'Качественный ламинат 32, 33 класса в магазине «Топтыгинпол» по выгодны ценам'};
        case 'quartz-vinyl': return {type: 2, name: 'Кварцвинил', seoTitle: 'Кварц-винил – где купить в Казани, бренды, виды, цены - ТОПТЫГИНПОЛ Казань', seoDescription: 'Интернет-магазин «Топтыгинпол» предлагает купить кварц-винил от ведущих производителей. Широкий ассортимент, актуальные варианты дизайна, все виды классности, консультации специалистов.'};
        case 'linoleum': return {type: 3, name: 'Линолеум', seoTitle: 'Линолеум – где купить в Казани, виды, фото, цены - ТОПТЫГИНПОЛ Казань', seoDescription: 'Интернет-магазин «Топтыгинпол» предлагает купить линолеум от ведущих производителей. Широкий ассортимент, актуальные варианты дизайна, все виды классности, помощь в расчете потребности.'};
        case 'components': return {type: 4, name: 'Комплектующие', seoTitle: 'Комплектующие - ТОПТЫГИНПОЛ Казань', seoDescription: 'Купить Комплектующие товары казань топтыгинпол напольные покрытия'};
        default: return this.$router.push('/catalog');
      }
    },
    onPageChange(page) {
      this.currentPage = page;
      window.scroll(0, 0);
    },
    showFilterResults() {
      if(window.screen.width <=768) {
        this.showFilters = false;
      }
      window.scroll(0, 0);
    },
    changeProduct(product) {
      this.editableProduct = product;
      this.productModal = true;
    },
    removeProduct(id) {
      if(!confirm('Действительно хотите удалить?')) return;

      this.$store.commit('removeProduct', {
        id: id,
        products: this.products,
      });
    },
    async addProduct() {
      const result = await axios.post(
          this.$store.state.params.apiURL,
          {
            action: 'add-product'
          }
      );
      this.fetchProducts();
      this.editableProduct = result.data
      this.productModal = true;
    }
  },
  mounted() {
    useMeta({
      title: this.getCatalogSection(this.$route.params.type).seoTitle,
      description: this.getCatalogSection(this.$route.params.type).seoDescription,
    })


    if(this.$store.state.catalog.currentCategory !== this.getCatalogSection(this.$route.params.type).type || this.$store.state.catalog.catalogFilters.count <= 0){
      this.$store.commit('changeCurrentCategory', this.getCatalogSection(this.$route.params.type).type);
      this.activeSort = '';
      this.currentPage = 1;
      this.fetchFilters();
    }else{
      this.fetchProducts();
    }

    if(window.screen.width <=768) {
      this.showFilters = false;
    }

    console.log(this.filters);


  },
  watch: {
    activeSort() {
      this.currentPage = 1;
      this.$store.commit('changeActiveSort', this.activeSort);
      this.fetchProducts();
    },
    currentPage() {
      this.$store.commit('changeCurrentPage', this.currentPage);
      this.fetchProducts();
    },
    filters: {
      handler() {
        this.$store.commit('changeCatalogFilters', this.filters);
        this.fetchProducts();
      },
      deep: true
    },
    $route: {
      handler() {
        this.fetchFilters();
      },
      deep: true
    }
  }
}
</script>

<style scoped>

.sale-line{
  border: 2px solid #DD204F;
  border-right: none;
  border-left: none;
  margin-top: 20px;
  width: 100%;
  overflow-x: hidden;
}
.sale-line .sl-container{
  width: 4000px;
  display: flex;
  animation: marquee 30s infinite linear;
}
.sale-line .sl-item{
  font-size: 30px;
  text-align: center;
  font-weight: 800;
  text-shadow: 2px 0 #DD204F, -2px 0 #DD204F, 0 2px #DD204F, 0 -2px #DD204F,
  1px 1px #DD204F, -1px -1px #DD204F, 1px -1px #DD204F, -1px 1px #DD204F;
  min-width: 400px;
  margin: 0 10px;
  color: #fff;
}
.sale-line .all-sl{
  display: flex;
  white-space: nowrap;
}
@keyframes marquee{
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(-50%)
  }
}


.up-sort{
  margin-right: 10px;
  margin-bottom: 5px;
}

.products-list-item {
  display: inline-block;
  margin-right: 10px;
}
.products-list-enter-active,
.products-list-leave-active {
  transition: all 0.5s ease;
}
.products-list-enter-from,
.products-list-leave-to {
  opacity: 0;
  transform: translateX(550px);
}


@media(max-width: 575.98px) {
  .main-title.left{
    text-align: center;
    margin-bottom: 20px;
  }
  .up-sort{
    display: none;
  }
  .sort-button{
    width: 100%;
  }
  .bread{
    justify-content: center;
  }
}

</style>