<template>
  <div class="btns">
    <div class="d-grid">
      <v-button
          class="btn-outline-dark dropdown-toggle"
          @click="isDropdownShowed = !isDropdownShowed;"
      >
        <span class="mob-prefix">Сортировка:</span> {{ dropdownItems.find(item => item.isActive === true).name }}
      </v-button>
    </div>

    <ul v-show="isDropdownShowed" class="dropdown-menu" tabindex="0">
      <li v-for="item in dropdownItems">
        <a class="dropdown-item" href="#" @click="$emit('changeDropdownItem', item); isDropdownShowed = false">{{ item.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'dropdown-button',
  data() {
    return{
      isDropdownShowed: false
    }
  },
  props: {
    dropdownItems: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
  .dropdown-menu{
    display: block;
    margin-top: 5px;
  }
  .btn:focus .mob-prefix{
    color: #fff;
  }
  @media (min-width: 1000px) {
    .mob-prefix{
      display: none;
    }
  }
</style>