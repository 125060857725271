<template>
  <v-input class="lg" :value="value" @input="$emit('update:value', $event.target.value)"></v-input>
</template>

<script>
export default {
  name: 'v-input-large',
  props: {
    value: [String, Number]
  },
}
</script>

<style scoped>
.form-control{
  font-weight: 600;
}
</style>