import {createStore} from "vuex";
import {basketModule} from "@/store/basketModule";
import {catalogModule} from "@/store/catalogModule";
import {authModule} from "@/store/authModule";
import {editModule} from "@/store/editModule";

export default createStore({
    state: () => ({
        contacts: {
            phone_1: '8 (987) 422-59-94',
            phone_2: '8 (937) 777-92-20',
            phone_link_1: 'tel: 89874225994',
            phone_link_2: 'tel: 89377779220',
            instagram_link: 'https://www.instagram.com/toptigynpol/',
            whatsapp_link: 'https://wa.me/79874225994',
            email: 'toptigynpol@mail.ru',
            worktime_1: 'Пн-пт с 10:00 до 20:00',
            worktime_2: 'Сб-вс с 10:00 до 18:00',
            address_min_1: 'Казань, К. Габишева 2',
            address_min_2: 'Казань, Адоратского 4',
            address_full_1: 'г. Казань, Комиссара Габишева 2',
            address_full_2: 'г. Казань, Адоратского 4',
            address_link_1: 'https://yandex.ru/maps/-/CDQHj0KD',
            address_link_2: 'https://yandex.ru/maps/-/CDQHjP0G',
            iframe_1: 'https://yandex.ru/map-widget/v1/?ll=49.226439%2C55.740797&mode=search&oid=130599934801&ol=biz&z=14.18',
            iframe_2: 'https://yandex.ru/map-widget/v1/?indoorLevel=1&ll=49.148096%2C55.823884&mode=search&oid=149667911668&ol=biz&z=16.9',
        },
        params: {
            apiURL: 'https://toptigynpol.ru/api.php',
            imagesURL: 'https://toptigynpol.ru/img/floors',
            salesURL: 'https://toptigynpol.ru/img/sales'
        }
    }),
    getters: {
        getApiUrl: (state) => {
            return state.params.apiURL
        }
    },
    modules: {
        basket: basketModule,
        catalog: catalogModule,
        auth: authModule,
        editModule: editModule
    }
});