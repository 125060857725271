<template>
  <div v-if="products.length > 0" class="products-slider-row">
    <swiper
        :modules="modules"
        :slides-per-view="4"
        :space-between="30"
        :navigation="true"
        :breakpoints="{ 300:{ slidesPerView: 2, spaceBetween: 15 }, 900:{ slidesPerView: 4, spaceBetween: 30 } }"
    >
      <swiper-slide v-for="product in products">
        <product-item :product="product" @removeProduct="removeProduct" @changeProduct="changeProduct"/>
      </swiper-slide>
    </swiper>


  </div>

  <product-modal :product="editableProduct" v-model:show="productModal"/>
</template>

<script>
import ProductItem from "@/components/Layout/ProductItem";
import ProductModal from "@/components/Admin/ProductModal";
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import {Navigation} from 'swiper/modules';
export default {
  components: {
    Swiper,
    SwiperSlide,
    ProductItem,
    ProductModal
  },
  data() {
    return {
      productModal: false,
      editableProduct: []
    }
  },
  props: {
    products: {
      type: Array,
      required: true
    }
  },
  methods: {
    changeProduct(product) {
      this.editableProduct = product;
      this.productModal = true;
      console.log(this.editableProduct);
    },
    removeProduct(id) {
      if(!confirm('Действительно хотите удалить?')) return;

      this.$store.commit('removeProduct', {
        id: id,
        products: this.products,
      });
    },
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
  // mounted() {
  //   this.triggerClick();
  // }


}
</script>

<style>
</style>