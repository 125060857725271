<template>
  <a
      :href="getCatalogSection(+product.type).link + product.link"
      :key="product.id"
      class="product-card"
  >
    <div
        class="image"
        :style="product.image.length > 0 ? 'background-image: url('+ $store.state.params.imagesURL + '/min/' + product.image[0]['img_url']+')': ''"
    >
      <div class="tags-wrap">
        <tag
            v-if="product.sale"
            class="sm danger"
        >
          -{{product.sale}}%
        </tag>
      </div>
    </div>
    <div
        class="name"
    >
      {{ product.name }}
    </div>
    <div class="d-block d-md-flex align-items-center justify-content-between">
      <div class="prices order-md-2">
        <div
            v-if="product.old_price != null && product.old_price !== 0"
            class="old-price"
        >
          {{product.old_price}}₽/м²
        </div>
        <div class="actual-price">{{product.price}} ₽/м²</div>
      </div>
      <v-button class="order-md-1">Подробнее</v-button>
    </div>
    <div class="d-flex justify-content-between mt-3" v-if="$store.getters.isAuth">
      <v-button class="btn-outline-danger" @click.prevent="$emit('changeProduct', product)">Изменить</v-button>
      <v-button class="btn-danger" @click.prevent="$emit('removeProduct', product.id)">Удалить</v-button>
    </div>
  </a>

</template>

<script>
export default {
  data() {
    return {
      productModal: false
    }
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  methods: {
    getCatalogSection(type) {
      switch (type){
        case 1: return {link: '/catalog/laminate/', name: 'Ламинат'};
        case 2: return {link: '/catalog/quartz-vinyl/', name: 'Кварцвинил'};
        case 3: return {link: '/catalog/linoleum/', name: 'Линолеум'};
        case 4: return {link: '/catalog/components/', name: 'Комплектующие'};
      }
    },
    changeProduct(event, column) {
      console.log(column)
      this.$store.commit('changeProductParam', {
        product: this.product,
        column: column,
        value: event.target.innerHTML
      });
    }
  }
}
</script>

<style scoped>
.product-card{
  cursor: pointer;
  padding: 15px;
  background-color: #F4F4F4;
  border-radius: 20px;
  display: block;
  text-decoration: none;
  transition: 0.3s;
  margin-bottom: 30px;
}
.product-card:hover{
  background-color: #EFEFEF;
}
.product-card .image{
  height: 190px;
  background-size: contain;
  background-color: #000;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 15px;
  padding: 0;
  margin-bottom: 15px;
}
.product-card .image .tags-wrap{
  justify-content: flex-start;
}
.product-card .image .tag{
  width: 80px;
  text-align: center;
}

.product-card .name{
  font-size: 16px;
  margin-bottom: 6px;
  height: 48px;
  overflow: hidden;
}
.product-card .old-price{
  text-decoration: line-through;
  font-size: 15px;
  text-align: right;
  color: #A5A5A5;
}
.product-card .actual-price{
  font-size: 18px;
  font-weight: 600;
  text-align: right;
}

@media (max-width: 575.98px) {
  .product-card{
    padding: 10px;
  }
  .product-card .image{
    height: 118px;
  }
  .product-card .prices{
    display: flex;
    align-items: center;
  }
  .product-card .old-price{
    font-size: 13px;
    margin-right: 5px;
  }
  .product-card .actual-price{
    font-size: 16px;
  }
  .product-card .btn{
    width: 100%;
    margin-top: 5px;
  }
}
</style>