<template>
  <a :href="feedback.link" rel="nofollow" target="_blank" class="feedback-block">
    <div class="date" v-show="feedback.date">{{feedback.date}}</div>
    <div class="head">
      <div class="icon">{{feedback.name[0]}}</div>
      <div>
        <div class="name">{{feedback.name}}</div>
        <div class="rating"></div>
      </div>
    </div>
    <div class="text">{{feedback.text}}</div>
    <showmo class="sm bold default">Читать полностью</showmo>
  </a>
</template>

<script>
export default {
  components: {},

  props: {
    feedback: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.feedback-block{
  padding: 25px;
  border-radius: 20px;
  background-color: #F5F5F5;
  position: relative;
  display: block;
  text-decoration: none;
}
.feedback-block .date{
  position: absolute;
  right: 15px;
  top: 15px;
  font-weight: 800px;
  font-size: 12px;
  color: #A5A5A5;
}
.feedback-block .head{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.feedback-block .icon{
  height: 60px;
  min-width: 60px;
  border-radius: 100%;
  font-size: 20px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: #A5A5A5;
  background-color: #D9D9D9;
  margin-right: 20px;
}
.feedback-block .name{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}
.feedback-block .rating{
  width: 112px;
  height: 20px;
  background-size: contain;
  background-position: left center;
  background-image: url(@/assets/img/rating.svg);
}
.feedback-block .text{
  height: 95px;
  overflow: hidden;
  margin-bottom: 10px;
  font-size: 16px;
}
</style>
