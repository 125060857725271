import { createApp } from 'vue'
import App from './App.vue'
import { createMetaManager } from 'vue-meta'
import components from '@/components/UI/index.js'
import router from "@/router/router"
import store from "@/store";




const app = createApp(App);

components.forEach(component => {
    app.component(component.name, component)
});

app
    .use(router)
    .use(store)
    .use(createMetaManager());

await router.isReady();
app.mount('#app');


