<template>
  <section v-if="salesList.length > 0">
    <div class="container">
      <div class="main-title">Акции</div>
      <div class="d-flex justify-content-center mb-5" v-if="$store.getters.isAuth">
        <v-button @click="addSale">Добавить акцию</v-button>
      </div>
      <div class="sales-wrap">
        <swiper
            :modules="modules"
            :slides-per-view="2"
            :space-between="30"
            :breakpoints="{ 300:{ slidesPerView: 1 }, 900:{ slidesPerView: 2 } }"
            :navigation="true"
        >
          <swiper-slide v-for="sale in salesList">
            <sale-item :sale="sale" @changeSale="changeSale" @removeSale="removeSale"/>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </section>
  <sale-modal @update:show="saleModal = false" :show="saleModal" :sale-item="editableSale"/>
</template>

<script>
import axios from "axios";
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import SaleItem from "@/components/Layout/SaleItem";
import {Navigation} from "swiper/modules";
import SaleModal from "@/components/Admin/SaleModal";

export default {
  components: {
    SaleModal,
    SaleItem,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      salesList: [],
      saleModal: false,
      editableSale: []
    }
  },
  methods: {
    async getSales() {
      const result = await axios.post(
          this.$store.state.params.apiURL,
          {
            action: 'get-sales'
          }
      );
      this.salesList = result.data;
    },
    changeSale(sale) {
      this.editableSale = sale;
      this.saleModal = true;
    },
    async addSale() {
      const result = await axios.post(
          this.$store.state.params.apiURL,
          {
            action: 'add-sale'
          }
      );
      this.getSales();
      this.editableSale = result.data
      this.saleModal = true;
    },
    removeSale(id) {
      if(!confirm('Действительно хотите удалить?')) return;

      this.$store.commit('removeSale', {
        id: id,
        sales: this.salesList,
      });
    },
  },
  mounted() {
    this.getSales()
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
}
</script>

<style scoped>

</style>