<template>
    <div class="header">
      <div class="top-nav">
        <div class="container d-flex justify-content-between">
          <div class="nav-item">
            <div class="logo" @click="$router.push('/')"></div>
          </div>
          <div class="nav-item">
            <div class="icon">
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM13 6.5C13 5.94772 12.5523 5.5 12 5.5C11.4477 5.5 11 5.94772 11 6.5V11.75C11 12.4404 11.5596 13 12.25 13H15.5C16.0523 13 16.5 12.5523 16.5 12C16.5 11.4477 16.0523 11 15.5 11H13V6.5Z" fill="#DD204F"></path> </g></svg>
            </div>
            <div class="content">
              <div class="value">{{ contacts.worktime_1 }}</div>
              <div class="value">{{ contacts.worktime_2 }}</div>
            </div>
          </div>
          <div class="nav-item">
            <div class="icon">
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3856 23.789L11.3831 23.7871L11.3769 23.7822L11.355 23.765C11.3362 23.7501 11.3091 23.7287 11.2742 23.7008C11.2046 23.6451 11.1039 23.5637 10.9767 23.4587C10.7224 23.2488 10.3615 22.944 9.92939 22.5599C9.06662 21.793 7.91329 20.7041 6.75671 19.419C5.60303 18.1371 4.42693 16.639 3.53467 15.0528C2.64762 13.4758 2 11.7393 2 10C2 7.34784 3.05357 4.8043 4.92893 2.92893C6.8043 1.05357 9.34784 0 12 0C14.6522 0 17.1957 1.05357 19.0711 2.92893C20.9464 4.8043 22 7.34784 22 10C22 11.7393 21.3524 13.4758 20.4653 15.0528C19.5731 16.639 18.397 18.1371 17.2433 19.419C16.0867 20.7041 14.9334 21.793 14.0706 22.5599C13.6385 22.944 13.2776 23.2488 13.0233 23.4587C12.8961 23.5637 12.7954 23.6451 12.7258 23.7008C12.6909 23.7287 12.6638 23.7501 12.645 23.765L12.6231 23.7822L12.6169 23.7871L12.615 23.7885C12.615 23.7885 12.6139 23.7894 12 23L12.6139 23.7894C12.2528 24.0702 11.7467 24.0699 11.3856 23.789ZM12 23L11.3856 23.789C11.3856 23.789 11.3861 23.7894 12 23ZM15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10Z" fill="#DD204F"></path> </g></svg>
            </div>
            <div class="content">
              <a href="#" class="value">{{ contacts.address_min_1 }}</a>
              <a href="#" class="value">{{ contacts.address_min_2 }}</a>
            </div>
          </div>
          <div class="nav-item">
            <div class="icon">
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M2.00589 4.54166C1.905 3.11236 3.11531 2 4.54522 2H7.60606C8.34006 2 9.00207 2.44226 9.28438 3.1212L10.5643 6.19946C10.8761 6.94932 10.6548 7.81544 10.0218 8.32292L9.22394 8.96254C8.86788 9.24798 8.74683 9.74018 8.95794 10.1448C10.0429 12.2241 11.6464 13.9888 13.5964 15.2667C14.008 15.5364 14.5517 15.4291 14.8588 15.0445L15.6902 14.003C16.1966 13.3687 17.0609 13.147 17.8092 13.4594L20.8811 14.742C21.5587 15.0249 22 15.6883 22 16.4238V19.5C22 20.9329 20.8489 22.0955 19.4226 21.9941C10.3021 21.3452 2.65247 13.7017 2.00589 4.54166Z" fill="#DD204F"></path> </g></svg>
            </div>
            <div class="content">
              <a :href="contacts.phone_link_1" class="value lg">{{ contacts.phone_1 }}</a>
              <a :href="contacts.phone_link_2" class="value lg">{{ contacts.phone_2 }}</a>
            </div>
          </div>
          <div class="mob-nav-item">
            <div class="logo" @click="$router.push('/')"></div>
          </div>
          <div class="mob-nav-item d-flex d-md-none justify-content-end">
            <div class="mob-action-item basket" @click="$router.push('/basket'); hideMenu()">
              <div class="basket-num-wrap">
                <div class="basket-num">{{ $store.state.basket.basket.length }}</div>
              </div>
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M8.41799 3.25089C8.69867 2.65917 9.30155 2.25 10 2.25H14C14.6984 2.25 15.3013 2.65917 15.582 3.25089C16.2655 3.25586 16.7983 3.28724 17.2738 3.47309C17.842 3.69516 18.3362 4.07266 18.6999 4.56242C19.0668 5.0565 19.2391 5.68979 19.4762 6.56144L20.2181 9.28272L20.4985 10.124C20.5065 10.1339 20.5144 10.1438 20.5222 10.1539C21.4231 11.3076 20.9941 13.0235 20.1362 16.4553C19.5905 18.638 19.3176 19.7293 18.5039 20.3647C17.6901 21.0001 16.5652 21.0001 14.3153 21.0001H9.68462C7.43476 21.0001 6.30983 21.0001 5.49605 20.3647C4.68227 19.7293 4.40943 18.638 3.86376 16.4553C3.00581 13.0235 2.57684 11.3076 3.47767 10.1539C3.48555 10.1438 3.4935 10.1338 3.50152 10.1239L3.7819 9.28271L4.52384 6.56145C4.76092 5.6898 4.93316 5.0565 5.30009 4.56242C5.66381 4.07266 6.15802 3.69516 6.72621 3.4731C7.20175 3.28724 7.73447 3.25586 8.41799 3.25089ZM8.41951 4.75231C7.75763 4.759 7.49204 4.78427 7.27224 4.87018C6.96629 4.98976 6.70018 5.19303 6.50433 5.45674C6.32822 5.69388 6.22488 6.0252 5.93398 7.09206L5.36442 9.18091C6.38451 9.00012 7.77753 9.00012 9.68462 9.00012H14.3153C16.2224 9.00012 17.6155 9.00012 18.6356 9.18092L18.066 7.09206C17.7751 6.0252 17.6718 5.69388 17.4957 5.45674C17.2998 5.19303 17.0337 4.98976 16.7278 4.87018C16.508 4.78427 16.2424 4.759 15.5805 4.75231C15.2992 5.3423 14.6972 5.75 14 5.75H10C9.30281 5.75 8.70084 5.3423 8.41951 4.75231Z" fill="#ffffff"></path> </g></svg>
            </div>
            <div class="mob-action-item menu-button" @click="isMenu = !isMenu">
              <svg class="menu-icon" v-if="!isMenu" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Menu / Menu_Alt_01"> <path id="Vector" d="M12 17H19M5 12H19M5 7H19" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
              <svg class="close-icon" v-if="isMenu" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Menu / Close_MD"> <path id="Vector" d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="divider"></div>
      </div>
    </div>
    <transition name="fade">
      <div class="bottom-nav" v-if="isMenu">
        <div class="container d-block d-md-flex justify-content-between">
          <div class="menu">
            <a
                :href="menu_item.path"
                :key="i"
                v-for="(menu_item, i) in this.menu"
                class="menu-item"
            >
              {{menu_item.name}}
            </a>
          </div>
          <div class="actions">
            <div class="action-wrap">
              <search-input @goToSearch="goToSearch"/>
            </div>
            <div class="action-wrap">
              <v-button class="btn-danger basket-button" :type="'link'" @click="$router.push('/basket'); hideMenu()">Корзина <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M8.41799 3.25089C8.69867 2.65917 9.30155 2.25 10 2.25H14C14.6984 2.25 15.3013 2.65917 15.582 3.25089C16.2655 3.25586 16.7983 3.28724 17.2738 3.47309C17.842 3.69516 18.3362 4.07266 18.6999 4.56242C19.0668 5.0565 19.2391 5.68979 19.4762 6.56144L20.2181 9.28272L20.4985 10.124C20.5065 10.1339 20.5144 10.1438 20.5222 10.1539C21.4231 11.3076 20.9941 13.0235 20.1362 16.4553C19.5905 18.638 19.3176 19.7293 18.5039 20.3647C17.6901 21.0001 16.5652 21.0001 14.3153 21.0001H9.68462C7.43476 21.0001 6.30983 21.0001 5.49605 20.3647C4.68227 19.7293 4.40943 18.638 3.86376 16.4553C3.00581 13.0235 2.57684 11.3076 3.47767 10.1539C3.48555 10.1438 3.4935 10.1338 3.50152 10.1239L3.7819 9.28271L4.52384 6.56145C4.76092 5.6898 4.93316 5.0565 5.30009 4.56242C5.66381 4.07266 6.15802 3.69516 6.72621 3.4731C7.20175 3.28724 7.73447 3.25586 8.41799 3.25089ZM8.41951 4.75231C7.75763 4.759 7.49204 4.78427 7.27224 4.87018C6.96629 4.98976 6.70018 5.19303 6.50433 5.45674C6.32822 5.69388 6.22488 6.0252 5.93398 7.09206L5.36442 9.18091C6.38451 9.00012 7.77753 9.00012 9.68462 9.00012H14.3153C16.2224 9.00012 17.6155 9.00012 18.6356 9.18092L18.066 7.09206C17.7751 6.0252 17.6718 5.69388 17.4957 5.45674C17.2998 5.19303 17.0337 4.98976 16.7278 4.87018C16.508 4.78427 16.2424 4.759 15.5805 4.75231C15.2992 5.3423 14.6972 5.75 14 5.75H10C9.30281 5.75 8.70084 5.3423 8.41951 4.75231Z" fill="#ffffff"></path> </g></svg></v-button>
              <div class="basket-num">{{ $store.state.basket.basket.length }}</div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="underheader"></div>
</template>

<script>
import $ from "@/assets/js/jquery-3.0.0.min.js";
import SearchInput from "@/components/UI/SearchInput";
export default {
  components: {SearchInput},
  props: {
    contacts: {
      type: Object,
      required: true
    }
  },

  data(){
    return {
      menu: [
        {name: 'Кварцвинил', path: '/catalog/quartz-vinyl'},
        {name: 'Ламинат', path: '/catalog/laminate'},
        {name: 'Линолеум', path: '/catalog/linoleum'},
        {name: 'Комплектующие', path: '/catalog/components'},
        {name: 'О компании', path: '/about'},
        {name: 'Отзывы', path: '/feedbacks'},
        {name: 'Акции', path: '/sales'},
        {name: 'Контакты', path: '/contacts'},
      ],
      isMenu: false

    }
  },
  methods: {
    hideMenu() {
      if(window.screen.width < 768 && this.isMenu === true) {
        this.isMenu = false;
      }
    },
    goToSearch(link) {
      this.$router.push(link);
      this.hideMenu();
    }
  },
  mounted() {
    if(window.screen.width >= 768) {
      this.isMenu = true;
    }
  }
}
</script>

<style>
  .header{
    background-color: #1A1A1A;
  }
  .header .top-nav{
    padding: 10px 0;
  }
  .logo{
    height: 55px;
    width: 290px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    background-image: url(@/assets/img/logo.svg);
    cursor: pointer;
  }
  .header .nav-item{
    display: flex;
    align-items: center;
  }
  .header .nav-item .icon{
    height: 50px;
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 2px solid #DD204F;
    margin-right: 10px;
  }
  .header .nav-item .icon svg{
    height: 25px;
    min-width: 25px;
  }
  .header .nav-item .value{
    display: block;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: 0.3s;
  }
  .header .nav-item a.value:hover{
    border-color: #fff;
  }
  .header .nav-item .value.lg{
    font-size: 18px;
  }
  .header .divider{
    height: 1px;
    background-color: #3D3D3D;
  }
  .bottom-nav{
    padding: 6px 0;
    position: sticky;
    top: 0;
    background-color: #1A1A1A;
    z-index: 101;
    transition: all 0.3s;
  }
  .bottom-nav .menu{
    display: flex;
    align-items: center;
    transition: all 0.3s;
  }
  .bottom-nav .menu .menu-item{
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin-right: 15px;
    margin-left: 15px;
    text-decoration: none;
    transition: 0.3s;
    cursor: pointer;
  }
  .bottom-nav .menu .menu-item:first-child{
    margin-left: 0;
  }
  .bottom-nav .menu .menu-item:last-child{
    margin-right: 0;
  }
  .bottom-nav .basket-button{
    position: relative;
    padding-right: 50px !important;
    margin-left: 10px;
  }
  .bottom-nav .basket-num{
    height: 20px;
    min-width: 20px;
    padding: 3px;
    width: auto;
    border-radius: 100%;
    right: -5px;
    top: -1px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #DD204F;
    background-color: #fff;
    position: absolute;
  }
  .bottom-nav .basket-button svg{
    position: absolute;
    display: flex;
    height: 31px;
    width: 31px;
    right: 15px;
    top: 3px;
  }
  .bottom-nav .actions{
    display: flex;
  }
  .bottom-nav .action-wrap{
    position: relative;
  }

  .header .mob-nav-item{
    display: none;
  }


  .fade-enter-active, .fade-leave-active{
    transition: opacity 0.3s;
    opacity: 1;

  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }


  @media(max-width: 575.98px) {
    .header{
      position: fixed;
      z-index: 101;
      width: 100%;
      top: 0;
    }
    .header .nav-item{
      display: none;
    }
    .header .mob-nav-item{
      display: block;
    }
    .header .logo{
      width: 208px;
      height: 42px;
    }
    .header .mob-action-item{
      height: 42px;
      width: 42px;
      margin-left: 15px;
      position: relative;
    }
    .header .mob-action-item .basket-num-wrap{
      position: absolute;
      display: flex;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      justify-content: center;
      align-items: center;
    }
    .header .mob-action-item .basket-num{
      height: 20px;
      width: 20px;
      padding: 3px;
      margin-top: 10px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      color: #DD204F;
      background-color: #fff;
    }
    .header svg{
      width: 100%;
    }
    .bottom-nav{
      position: fixed;
      z-index: 101;
      width: 100%;
      top: 62px;
    }
    .bottom-nav .menu{
      flex-direction: column;
    }
    .bottom-nav .menu .menu-item{
      margin: 10px 0;
      font-size: 17px;
    }
    .bottom-nav .actions{
      flex-direction: column;
      align-items: center;
    }
    .bottom-nav .action-wrap{
      width: 300px;
      margin-bottom: 10px;
    }
    .bottom-nav .search-input{
      min-width: 100%;
      text-align: center;
    }
    .bottom-nav .basket-button{
      min-width: 100%;
      margin: 0;
      padding: 5px 60px !important;
    }
    .underheader{
      height: 60px;
    }

    /*.header .mob-action-item .menu-button .menu-icon{*/
    /*  display: block;*/
    /*}*/
    /*.header .mob-action-item .menu-button .close-icon{*/
    /*  display: none;*/
    /*}*/
    /*.header .mob-action-item .menu-button.active .menu-icon{*/
    /*  display: none;*/
    /*}*/
    /*.header .mob-action-item .menu-button .close-icon{*/
    /*  display: none;*/
    /*}*/

  }

</style>