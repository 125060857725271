<template>
  <categories-list/>
  <Contacts/>
</template>

<script>
import CategoriesList from "@/components/Layout/CategoriesList";
import Contacts from "@/components/Layout/Contacts";
import {useMeta} from "vue-meta";
export default {
  components: {Contacts, CategoriesList},
  setup () {
    useMeta({
      title: 'Топтыгинпол - каталог напольных покрытий в Казани по выгодной цене - ТОПТЫГИНПОЛ Казань',
      description: 'Топтыгинпол - каталог напольных покрытий в Казани! Огромный выбор ламината, линолеума, кварцвинила. Наш адрес: г. Казань, Габишева 2. Тел: 8 (987) 422-59-94',
    })
  },
}
</script>

<style scoped>

</style>