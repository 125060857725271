<template>
  <div class="footer">
    <div class="container">
      <div class="footer-top">
        <div class="column">
          <div class="name">Каталог</div>
          <div><a href="#" class="value">Ламинат</a></div>
          <div><a href="#" class="value">Линолеум</a></div>
          <div><a href="#" class="value">Кварц-винил</a></div>
          <div><a href="#" class="value">Комплектующие</a></div>
        </div>
        <div class="column">
          <div class="name">Информация</div>
          <div><a href="#" class="value">Доставка</a></div>
          <div><a href="#" class="value">Возврат товара</a></div>
          <div><a href="#" class="value">Услуги укладки</a></div>
          <div><a href="#" class="value">Сотрудничество</a></div>
        </div>
        <div class="column">
          <div class="name">Компания</div>
          <div><a href="#" class="value">О компании</a></div>
          <div><a href="#" class="value">Производители</a></div>
          <div><a href="#" class="value">Реквизиты</a></div>
          <div><a href="#" class="value">Контакты</a></div>
        </div>
        <div class="column">
          <div class="name">Есть вопросы?</div>
          <v-button class="btn-light">Связаться с нами</v-button>
        </div>
      </div>
      <div class="divider"></div>
      <div class="footer-bottom">
        <div class="logo"></div>
        <div class="notice">© 2023 | Все права защищены</div>
        <div class="notice">Разработано в <a href="https://digital-quality.ru" rel="nofollow" target="_blank">Digital Quality</a></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.footer{
  background-color: #1A1A1A;
  overflow-x: hidden;
}
.footer .footer-top{
  padding: 50px 0;
  display: none;
  justify-content: space-between;
}
.footer .name{
  margin-bottom: 20px;
  font-size: 20px;
  color: #fff;
  font-weight: 700;
}
.footer .value{
  text-decoration: none;
  color: #fff;
  margin-bottom: 10px;
  display: inline-block;
  border-bottom: 1px solid transparent;
  transition: 0.3s;
}
.footer .value:hover{
  border-color: #fff;
}
.footer .divider{
  height: 2px;
  background-color: #2C2C2C;
}
.footer .footer-bottom{
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer .notice{
  font-size: 18px;
  font-weight: 300;
  color: #fff;
}
.footer .notice a{
  text-decoration: none;
  color: #fff;
  border-bottom: 1px solid #fff;
  transition: 0.3s;
}
.footer .notice a:hover{
  border-color: transparent;
}

@media (max-width: 575.98px) {
  .footer .column{
    width: 40%;
    margin-bottom: 30px;
  }
  .footer .footer-top{
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .footer .footer-top{
    display: none;
  }
  .footer .footer-bottom{
    flex-direction: column;
  }
  .footer .footer-bottom .notice{
    margin-top: 20px;
  }
}
</style>