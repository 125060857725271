<template>
  <div class="check-group" @click="$emit('changeCheckbox', checkbox)">
    <div class="checkbox" :class="checkbox.isActive===true ? checkbox.class+' active' : checkbox.class"></div>
    <div class="name">{{checkbox.text}}</div>
  </div>
</template>

<script>
export default {
  props: {
    checkbox: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.check-group{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.checkbox{
  height: 30px;
  width: 30px;
  border: 2px solid #A5A5A5;
  position: relative;
  margin-right: 15px;
}
.checkbox.active::before{
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #DD204F;
  top: 3px;
  right: 3px;
}
.checkbox.radio, .checkbox.radio.active::before{
  border-radius: 100%;
}
</style>