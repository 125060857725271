<template>
  <AboutCompany/>
  <RealisedWorks/>
  <Contacts/>
</template>

<script>
import AboutCompany from "@/components/Layout/AboutCompany";
import RealisedWorks from "@/components/Layout/RealisedWorks";
import Contacts from "@/components/Layout/Contacts";
export default {
  components: {Contacts, RealisedWorks, AboutCompany}
}
</script>

<style scoped>

</style>