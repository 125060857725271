<template>
  <form @submit.prevent="sendForm" class="search-form">
    <input type="text" class="search-input" id="searchInput" placeholder="Поиск">
    <svg class="search-button" @click="sendForm" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#ffffff" fill-rule="evenodd" d="M4 9a5 5 0 1110 0A5 5 0 014 9zm5-7a7 7 0 104.2 12.6.999.999 0 00.093.107l3 3a1 1 0 001.414-1.414l-3-3a.999.999 0 00-.107-.093A7 7 0 009 2z"></path> </g></svg>
  </form>
</template>

<script>
export default {
  methods: {
    sendForm(){
      const input = document.getElementById("searchInput");
      if(input.value === '') return input.focus();
      this.$emit('goToSearch', '/search?s='+encodeURI(input.value));
    }
  }
}
</script>

<style scoped>
.search-input{
  border: 2px solid #fff;
  border-radius: 40px;
  height: 40px;
  padding: 5px 25px;
  font-size: 15px;
  color: #fff;
  background-color: transparent;
  font-weight: 600;
  max-width: 180px;

}
.search-input::placeholder{
  color: #fff;
}
.search-button{
  position: absolute;
  height: 25px;
  width: 25px;
  right: 20px;
  top: 7px;
}
.search-button:hover{
  cursor: pointer;
}
</style>