<template>
  <div v-if="filters.length > 0" class="catalog-menu">
    <catalog-filter-item v-for="filter in filters" :param="filter" @checkFilterItem="checkFilterItem" @changeFilterStatus="changeFilterStatus"/>
    <div class="menu-item d-grid">
      <v-button class="btn-danger mb-2" @click="$emit('showFilterResults')">Показать</v-button>
      <v-button class="btn-outline-danger" @click="$emit('resetFilterParams')">Сбросить</v-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Array,
      required: true
    }
  },
  methods: {
    checkFilterItem(param_id, value) {
      this.$emit('checkFilterItem', param_id, value);
    },
    changeFilterStatus(param_id) {
      this.$emit('changeFilterStatus', param_id);
    }
  }
}
</script>

<style scoped>
.catalog-menu{
  border-radius: 20px;
  background-color: #F4F4F4;
  padding: 30px 25px;
}

</style>