let basket = window.localStorage.getItem('basket');
let basketTotalPrice = window.localStorage.getItem('basketTotalPrice');

export const basketModule = {
    state: () => ({
        basket: basket ? JSON.parse(basket) : [],
        basketTotalPrice: basketTotalPrice ? +basketTotalPrice : 0
    }),
    mutations: {
        addToBasket(state, product) {
            if(!this.getters.isInBasket(product.id) && product.packs > 0) state.basket.push(product);
            this.commit('setProductTotalPrice', product.id);
        },
        updateBasketItemCount(state, product){
            if(product.packs <= 0) return this.commit('removeFromBasket', product.id);
            state.basket.forEach(function (item) {
                if(item.id === product.id) {
                    return item.packs = product.packs;
                }
            });
            this.commit('setProductTotalPrice', product.id);
        },
        removeFromBasket(state, product_id) {
            state.basket.forEach(function (item, i) {
                if(item.id === product_id) {
                    state.basket.splice(i, 1);
                }
            });
            this.commit('setProductTotalPrice', product_id);
        },
        setProductTotalPrice(state, product_id = false) {
            state.basketTotalPrice = 0;
            let product = state.basket.find(product => product.id === product_id);
            if(product){
                if(product.packPrice > 0) {
                    product.totalSquare = this.getters.roundNum(product.pack_square * product.packs);
                    product.totalPrice = this.getters.roundNum(product.packPrice * product.packs);
                }else{
                    product.totalSquare = this.getters.roundNum(product.packs);
                    product.totalPrice = this.getters.roundNum(product.price * product.packs);
                }
            }
            state.basket.forEach((product) => state.basketTotalPrice = this.getters.roundNum(state.basketTotalPrice + product.totalPrice));
            this.commit('saveBasket');
        },
        saveBasket(state) {
            window.localStorage.setItem('basket', JSON.stringify(state.basket));
            window.localStorage.setItem('basketTotalPrice', state.basketTotalPrice);
        }
    },
    getters: {
        isInBasket: (state) => (product_id) => {
            let product = state.basket.find(product => product.id === product_id);
            return product !== undefined;
        },
        roundNum: (state) => (num) => {
            num = Number(num);
            if(num % 1 !== 0) {
                num = num.toFixed(2);
                num = num.replace(/0*$/,"");
            }
            return +num;
        }
    }
}