<template>
  <div class="text-lg">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'text-lg'
}
</script>

<style scoped>
.text-lg{
  font-size: 20px;
}
</style>