<template>
  <button v-if="type === 'button'" class="btn"><slot></slot></button>
  <a class="btn" v-else><slot></slot></a>
</template>

<script>
export default {
  name: 'v-button',
  props: {
    type: {
      type: String,
      default: 'button'
    }
  }
}
</script>

<style scoped>
.btn{
  border-radius: 40px;
  height: 40px;
  padding: 5px 25px;
  font-size: 15px;
  font-weight: 600;
  background-color: #3A3A3A;
  border-width: 2px;
  border-color: #3A3A3A;
  color: #fff;
}
.btn-lg{
  height: 60px;
  padding: 10px 35px;
  font-size: 18px;
}
.btn:hover{
  background-color: #2D2D2D;
  border-color: #2D2D2D;
  color: #fff;
}
.btn-danger{
  background-color: #DD204F;
  border-color: #DD204F;
}
.btn-light{
  background-color: #fff;
  border-color: #fff;
  color: #1A1A1A;
}
.btn-light:hover{
  background-color: #E8E8E8;
  border-color: #E8E8E8;
  color: #1A1A1A;
}
.btn-danger:hover{
  background-color: #C71A45;
  border-color: #C71A45;
}
.btn-outline-danger{
  background-color: transparent;
  color: #DD204F;
  border-color: #DD204F;
}
.btn-outline-dark{
  border-color: #3A3A3A;
  color: #3A3A3A;
  background-color: transparent;
}
.btn-outline-dark:hover{
  background-color: #2D2D2D;
  border-color: #2D2D2D;
  color: #fff;
}
.btn-danger:hover, .btn-outline-danger:hover{
  background-color: #C71A45;
  border-color: #C71A45;
}
</style>