<template>
  <section>
    <div class="container">
      <div class="main-title">Отзывы покупателей</div>
      <div class="feedbacks-wrap">
        <iframe src="https://yandex.ru/maps-reviews-widget/130599934801?comments"></iframe>
      </div>
    </div>
  </section>
  <Contacts/>
</template>

<script>
import Contacts from "@/components/Layout/Contacts";
export default {
  components: {Contacts}
}
</script>

<style scoped>
  .feedbacks-wrap{
    width: 100%;
    border-radius: 20px;
    background-color: #F5F5F5;
    height: 1300px;
    display: flex;
    justify-content: center;
  }
  .feedbacks-wrap iframe{
    min-height: 100%;
    width: 750px;
  }
</style>