<template>
  <div class="not-found"><slot></slot></div>
</template>

<script>
export default {
  name: 'not-found'
}
</script>

<style scoped>
.not-found{
  color: #ced4da;
  margin: 50px auto;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
}
</style>