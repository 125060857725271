<template>
  <a href="https://api.whatsapp.com/send/?phone=89874225994&text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C!%20%D0%A5%D0%BE%D1%87%D1%83%20%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B8%D1%82%D1%8C%20%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8E%20%D0%BE%D0%B1%20%D0%B0%D0%BA%D1%86%D0%B8%D0%B8!&type=phone_number&app_absent=0" rel="nofollow" target="_blank" class="sale-block">
    <div class="image"  :style="{'background-image': `url('${$store.state.params.salesURL}/${sale.img_url}')`}">
      <div class="tags-wrap">
        <tag v-if="sale.deadline" class="danger">{{sale.deadline}}</tag>
      </div>
    </div>
    <div class="content">
      <div class="name">{{sale.name}}</div>
      <v-button>Получить</v-button>

    </div>
  </a>
  <div class="d-flex justify-content-center mt-2" v-if="$store.getters.isAuth">
    <v-button class="btn-outline-danger mx-1" @click="$emit('changeSale', sale)">Изменить</v-button>
    <v-button class="btn-danger mx-1" @click="$emit('removeSale', sale.id)">Удалить</v-button>
  </div>
</template>

<script>
import VButton from "@/components/UI/VButton";
export default {
  components: {VButton},

  props: {
    sale: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.sale-block{
  border-radius: 20px;
  overflow: hidden;
  display: block;
  text-decoration: none;
}
.sale-block .image{
  padding: 20px;
  height: 400px;
  background-size: contain;
  background-color: #EFEFEF;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.5s;
}
.sale-block .content{
  height: 100px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F5F5F5;
  transition: 0.3s;
}
.sale-block .name{
  font-size: 20px;
  font-weight: 600;
}
.sale-block .btn{
  min-width: 160px;
  margin-left: 30px;
}
.sale-block:hover .image{
  opacity: 0.8;
}
.sale-block:hover .content{
  background-color: #EFEFEF;
}
@media (max-width: 575.98px) {
  .sale-block .image{
    height: 250px;
  }
  .sale-block .name{
    font-size: 18px;
  }
  .sale-block .btn{
    margin-left: 10px;
    min-width: auto;
  }
}
</style>