<template>
  <Contacts/>
</template>

<script>
import Contacts from "@/components/Layout/Contacts";
export default {
  components: {Contacts}
}
</script>

<style scoped>

</style>