<template>
  <div class="bread" v-if="crumbs">
    <div class="bread-item-wrap" v-for="crumb in crumbs">
      <a :href="crumb.href" class="bread-item">{{crumb.name}}</a>
      <div class="bread-arrow" v-if="!crumb.isCurrent">–</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bread-crumbs',
  props: {
    crumbs: {
      type: Array,
      required: true
    }
  },
  mounted() {
    console.log(this.$route);
  }
}
</script>

<style scoped>
.bread{
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}
.bread-item-wrap{
  display: flex;
}
.bread-item, .bread-arrow{
  font-size: 18px;
  color: #A5A5A5;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: 0.3s;
}
.bread-arrow{
  margin-right: 12px;
  margin-left: 12px;
}
.bread-item:hover{
  border-color: #A5A5A5;
  cursor: pointer;
}
.bread-item.active:hover{
  border-color: transparent;
  cursor: default;
}
.up-sort{
  font-size: 18px;
  color: #A5A5A5;
  margin-bottom: 10px;
}
</style>