import axios from "axios";

export const editModule = {
    mutations: {
        async changeProductParam(state, params) {

            //if(params.value === '') return;

            // if(params.column === 'price' && !isNaN(+params.value)) {
            //
            //     const percent = (+params.product.sale.replace(/[^+\d]/g, '') / 100) + 1;
            //
            //     params.value = +params.value
            //     params.product.price = params.value;
            //     params.product.old_price = this.getters.roundNum(params.value * percent);
            //     params.product.pack_price = this.getters.roundNum(params.value * +params.product.pack_square);
            //     params.product.pack_old_price = this.getters.roundNum(params.product.pack_price * percent);
            //
            //     console.log(params.product);
            //
            //     return await axios.post(
            //         this.getters.getApiUrl,
            //         {
            //             action: 'change-product-price',
            //             id: params.product.id,
            //             price: params.product.price,
            //             old_price: params.product.old_price,
            //             pack_price: params.product.pack_price,
            //             pack_old_price: params.product.pack_old_price
            //         }
            //     );
            // }

            params.product[params.column] = params.value;
            return await axios.post(
                this.getters.getApiUrl,
                {
                    action: 'change-product-param',
                    id: params.product.id,
                    column: params.column,
                    value: params.value
                }
            );
        },
        async addImage(state, params){
            const formData = new FormData();
            formData.append('action', 'add-product-image');
            formData.append('id', params.product.id);

            for( var i = 0; i < params.files.length; i++ ){
                const file = params.files[i];
                formData.append('files[' + i + ']', file);
            }

            const result = await axios.post(
                this.getters.getApiUrl,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
            params.product.image = result.data;
        },
        async removeImage(state, params) {
            const result = await axios.post(
                this.getters.getApiUrl,
                {
                    action: 'remove-product-image',
                    id: params.id
                }
            );
            params.product.image.splice(params.product.image.findIndex((img) => img.id === params.id), 1);
        },
        changeParameter(state, params) {
            axios.post(
                this.getters.getApiUrl,
                {
                    action: 'change-parameter',
                    id: params.id,
                    value: params.value
                }
            );
            params.product.params.map((p) => p.id === params.id ? p.value = params.value : p.value);
        },
        removeProductParam(state, params) {
            axios.post(
                this.getters.getApiUrl,
                {
                    action: 'remove-product-param',
                    id: params.id
                }
            );
            params.product.params.splice(params.product.params.findIndex((p) => p.id === params.id), 1);
        },
        async addProductParam(state, params) {
            console.log(params);
            const result = await axios.post(
                this.getters.getApiUrl,
                {
                    action: 'add-product-param',
                    floor_id: params.product.id,
                    type: params.product.type,
                    param_id: params.value
                }
            );
            params.product.params.push(result.data);
        },
        removeProduct(state, params) {
            axios.post(
                this.getters.getApiUrl,
                {
                    action: 'remove-product',
                    id: params.id
                }
            );
            params.products.splice(params.products.findIndex((p) => p.id === params.id), 1);
        },
        changeSale(state, params) {
            axios.post(
                this.getters.getApiUrl,
                {
                    action: 'change-sale',
                    id: params.sale.id,
                    column: params.column,
                    value:params.value
                }
            )
            params.sale[params.column] = params.value;
        },
        async addSaleImage(state, params){
            const formData = new FormData();
            formData.append('action', 'add-sale-image');
            formData.append('id', params.sale.id);
            formData.append('file', params.file);

            const result = await axios.post(
                this.getters.getApiUrl,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
            location.reload();
            params.sale.img_url = result.data;

        },
        removeSale(state, params) {
            axios.post(
                this.getters.getApiUrl,
                {
                    action: 'remove-sale',
                    id: params.id
                }
            );
            params.sales.splice(params.sales.findIndex((p) => p.id === params.id), 1);
        },
    }
}