<template>
  <section class="product-page">
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <swiper
              :modules="modules"
              :slides-per-view="1"
              :space-between="30"
              :loop="true"
              navigation
          >
            <swiper-slide v-for="(image, i) in product.images">
              <div class="product-page-image" :style="{'background-image': 'url('+image+')'}" @click="openGallery(i)">
                <div class="tags-wrap" v-if="product.sale">
                  <tag class="lg danger">-{{product.sale}}%</tag>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="col-md-6 offset-md-1">
          <bread-crumbs
              :crumbs="crumbs"
          />
          <div class="main-title left">{{product.name}}</div>
          <div class="pp-info-links">
            <showmo class="dark">Товар в наличии</showmo>
            <showmo class="danger mx-5" target="_blank" rel="nofollow" href="https://api.whatsapp.com/send/?phone=89874225994&text=Добрый%20день!%20Хочу%20получить%20доп%20об%20скидку!&type=phone_number&app_absent=0">Получить доп. скидку</showmo>
            <!--<showmo class="dark mx-5" @click="isCheaper = true">Нашли дешевле?</showmo>-->
<!--            <v-modal v-model:show="isCheaper">-->
<!--              <div class="modal-title">Нашли дешевле?</div>-->
<!--              <div class="modal-descr">Заполните форму, и мы свяжемся с вами для уточнения стоимости</div>-->
<!--              <callback-form @formSent="cheaperFormSent" />-->
<!--            </v-modal>-->
          </div>
          <div class="pp-prices-wrap">
            <div class="pp-prices" v-if="product.pack_price > 0">
              <div class="price-name">Цена за упаковку</div>
              <div v-if="product.pack_price && +product.pack_price !== 0" class="actual-price">{{ product.pack_price }} ₽/уп</div>
              <div v-if="product.pack_old_price && +product.pack_old_price !== 0" class="old-price">{{ product.pack_old_price }} ₽/уп</div>
            </div>
            <div class="pp-prices" v-if="product.price > 0">
              <div class="price-name">Цена за м²</div>
              <div v-if="product.price" class="actual-price">{{ product.price }} ₽/м²</div>
              <div v-if="product.old_price && +product.old_price != 0" class="old-price">{{ product.old_price }} ₽/м²</div>
            </div>
          </div>

          <div class="action-buttons">
            <div class="d-flex justify-content-between justify-content-md-start">
              <div class="product-count">
                <calc-input v-model:value="calcValues.packs" :objKey="'packs'" @updateCalcInput="updateCalcInput"></calc-input>
              </div>
              <v-button class="btn-lg btn-danger add-basket" v-if="!$store.getters.isInBasket(product.id)" @click="addToBasket">В корзину</v-button>
              <v-button class="btn-lg add-basket" v-else @click="$store.commit('removeFromBasket', product.id)">Убрать из корзины</v-button>
            </div>
            <!--<div class="d-grid">
              <v-button class="btn-outline-danger buy-one-click">Купить в 1 клик</v-button>
            </div>-->
          </div>

        </div>
      </div>
    </div>
  </section>
  <image-gallery :images="galleryImages" v-model:show="showGallery"/>
  <product-calculator v-if="product.pack_price > 0" :calcValues="calcValues" :product="product" @updateCalcInput="updateCalcInput" @addToBasket="addToBasket" @changeStock="changeStock"></product-calculator>
  <product-tabs :tabs="tabs" @setTab="setTab"/>
  <section v-if="false">
    <div v-if="relatedProducts.length > 0" class="container">
      <div class="main-title">Сопутствующие товары</div>
      <products-slider :products="relatedProducts"/>
    </div>
  </section>
  <asking-form/>
  <section v-if="similarProducts.length > 0">
    <div class="container">
      <div class="main-title">Похожие товары</div>
      <products-slider :products="similarProducts"/>
    </div>
  </section>
  <users-feedbacks/>
  <Contacts/>
</template>

<script>
import axios from "axios";
import {Swiper, SwiperSlide} from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import ProductCalculator from "@/components/Layout/ProductCalculator";
import ProductTabs from "@/components/Layout/ProductTabs";
import ProductsSlider from "@/components/Layout/ProductsSlider";
import AskingForm from "@/components/Layout/AskingForm";
import UsersFeedbacks from "@/components/Layout/UsersFeedbacks";
import CalcInput from "@/components/UI/CalcInput";
import Contacts from "@/components/Layout/Contacts";
import CallbackForm from "@/components/Layout/CallbackForm";
import {useMeta} from "vue-meta";
import ImageGallery from "@/components/Layout/ImageGallery";

export default {
  components: {
    ImageGallery,
    CallbackForm,
    CalcInput,
    UsersFeedbacks,
    AskingForm,
    ProductsSlider,
    ProductTabs,
    ProductCalculator,
    Swiper,
    SwiperSlide,
    Contacts,
  },
  data() {
    return {
      link: this.$route.params.link,
      isCheaper: false,
      product: {},
      relatedProducts: [],
      similarProducts: [],
      tabs: [],
      calcValues: {},
      oldCalcValues: {},
      crumbs: [
        {href: '/', name: 'Главная'},
        {href: '/catalog', name: 'Каталог'},
        {href: '/catalog/'+this.$route.params.type, name: this.getCatalogSectionName(this.$route.params.type), isCurrent: true}
      ],
      galleryImages: [],
      showGallery: false,
    }
  },
  methods: {
    async getProduct() {
      try {
        const response = await axios.post(
            this.$store.state.params.apiURL,
            {
              action: 'get-product',
              link: this.link
            }
        );
        this.product = response.data;
        await this.getProductsLists(this.product.id, this.product.type,'get-related-products', 'relatedProducts');
        await this.getProductsLists(this.product.id, this.product.type,'get-similar-products', 'similarProducts');


        this.tabs = [
          {
            name: 'Характеристики',
            isActive: true,
            content: this.product.params,
            type: Array
          },
          {
            name: 'Описание',
            isActive: false,
            content: this.product.description,
            type: String
          },
          {
            name: 'Доставка и оплата',
            isActive: false,
            content: 'Информация о доставке и оплате',
            type: String
          },
        ];

        if(this.$store.getters.isInBasket(this.product.id)){
          let prod_id = this.product.id;
          let basketProduct = this.$store.state.basket.basket.find(prod => prod.id === prod_id);
          this.calcValues = {
            packSquare: this.product.pack_square,
            packPrice: this.product.pack_square * this.product.price,
            packs: basketProduct.packs,
            totalSquare: basketProduct.totalSquare,
            totalPrice: basketProduct.totalPrice,
            totalStockSquare: this.product.pack_square,
            stock: 0
          }
        }else{
          this.calcValues = {
            packSquare: this.product.pack_square,
            packPrice: this.product.pack_square * this.product.price,
            packs: 1,
            totalSquare: this.product.pack_square,
            totalPrice: this.product.pack_square * this.product.price,
            totalStockSquare: this.product.pack_square,
            stock: 0
          }
        }

        this.oldCalcValues = {...this.calcValues};

        console.log(this.product);
      } catch (e) {
        console.log(e);
      }
    },
    async getProductsLists(productId, product_type, action, key) {
      try {
        const response = await axios.post(
            this.$store.state.params.apiURL,
            {
              action: action,
              type: product_type,
              product_id: productId
            }
        );
        this[key] = response.data;
      } catch (e) {
        alert('Ошибка');
      }
    },
    setTab(tab){
      this.tabs.forEach(function (tab_item) {
        tab_item.isActive = tab_item === tab;
      })
    },
    updateCalcInput(event, key){
      if(event === 'plus') this.calcValues[key]++;
      else if(this.calcValues[key] >= 1) this.calcValues[key]--;
      this.calcValues[key] = this.$store.getters.roundNum(this.calcValues[key]);
    },
    changeStock(stock){
      this.calcValues.stock = stock;
    },
    addToBasket(){
      if(!this.$store.getters.isInBasket(this.product.id)) {
        this.product.packs = this.calcValues.packs;
        this.product.packPrice = this.calcValues.packPrice;
        this.$store.commit('addToBasket', this.product);
      }
    },
    cheaperFormSent(){
      this.isCheaper = false;
    },
    getCatalogSectionName(type) {
      switch (type){
        case 'laminate': return 'Ламинат';
        case 'quartz-vinyl': return 'Кварцвинил';
        case 'linoleum': return 'Линолеум';
        case 'components': return 'Комплектующие';
      }
    },
    openGallery(i) {
      const firstElement = this.product.images[i];
      this.galleryImages = this.product.images.toSpliced(i, 1);
      this.galleryImages.unshift(firstElement);
      this.showGallery = true;
    }
  },
  mounted() {
    this.getProduct();
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
  watch: {
    calcValues: {
      handler(newVal) {

        Object.keys(this.calcValues).forEach(key => {
          if(this.calcValues[key] !== this.oldCalcValues[key]){
            if(key === 'packs'){
              this.calcValues.totalSquare = this.$store.getters.roundNum(this.calcValues.packs * this.calcValues.packSquare);
            }else if(key === 'totalSquare'){
              this.calcValues.packs = this.$store.getters.roundNum(Math.ceil(this.calcValues.totalSquare/this.calcValues.packSquare));
            }
          }
          this.calcValues.totalPrice = this.$store.getters.roundNum(this.calcValues.packs * this.calcValues.packPrice);
          this.calcValues[key] = this.$store.getters.roundNum(this.calcValues[key]);
        });
        this.oldCalcValues = {...this.calcValues};

        this.product.packs = this.calcValues.packs
        this.$store.commit('updateBasketItemCount', this.product);

      },
      deep: true
    },
    $route: {
      handler() {
        this.getProduct();
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.product-page .product-page-image{
  height: 400px;
  cursor: pointer;
  background-size: contain;
  background-color: #000;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
}
.product-page .main-title{
  margin-bottom: 30px;
}
.product-page .pp-info-links{
  display: flex;
  margin-bottom: 30px;
}
.product-page .pp-link:last-child{
  margin-left: 30px;
}
.product-page .pp-prices-wrap{
  display: flex;
}
.product-page .pp-prices{
  margin-bottom: 40px;
  margin-right: 60px;
}
.product-page .price-name{
  color: #A5A5A5;
  font-size: 16px;
}
.product-page .actual-price{
  font-size: 26px;
  font-weight: 700;
}
.product-page .old-price{
  font-size: 22px;
  font-weight: 600;
  text-decoration: line-through;
  color: #A5A5A5;
}
.product-page .product-count{
  width: 240px;
}
.product-page .product-count .icon{
  color: #A5A5A5;
  font-weight: 700;
  font-size: 35px;
  line-height: 1;
  cursor: pointer;
  transition: 0.3s;
}
.product-page .product-count .icon:hover{
  opacity: 0.5;
}
.product-page .product-count .num{
  font-size: 20px;
  font-weight: 700;

}

.product-page .add-basket{
  min-width: 240px;
  margin-left: 15px;
}
.product-page .buy-one-click{
  margin-top: 10px;
  max-width: 420px;
}

.product-page .tags-wrap{
  justify-content: flex-start;
}
.product-page .tag{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 20px;
  min-width: 130px;
}


@media(max-width: 575.98px) {
  .product-page .bread{
    margin-top: 15px;
  }
  .product-page .product-page-image {
    height: 280px;
  }
  .product-page .product-count{
    max-width: 180px;
  }
  .product-page .add-basket{
    min-width: 180px;
    padding: 10px;
  }
  .product-page .pp-prices:last-child{
    margin-right: 0;
  }
  .product-page .tag{
    height: 40px;
    font-size: 18px;
    min-width: 120px;
  }
}

</style>