<template>
  <v-modal size="lg" @update:show="$emit('update:show', false)" :show="show">
    <div class="main-title">Редактирование товара</div>

    <div class="mb-3">
      <swiper
          :modules="modules"
          :slides-per-view="1"
          :space-between="30"
          :navigation="true"
      >
        <swiper-slide v-for="image in product.image">
          <div class="edit-product-image" :style="{backgroundImage: `url(${$store.state.params.imagesURL}/full/${image['img_url']})`}">
            <v-button @click="removeImage(image.id)">Удалить</v-button>
          </div>
        </swiper-slide>
      </swiper>
      <input type="file" class="d-none" id="image-input" name="image" accept="image/png, image/jpeg" multiple @change="addImage($event)">
      <div class="d-grid">
        <v-button class="btn-danger" @click="triggerInput">Добавить изображение</v-button>
      </div>
    </div>

    <div
        class="mb-3"
        v-for="(field, key) in formFields"
    >
      <div class="form-label" :attr="product[key]">{{field.name}}</div>
      <v-textarea
          v-if="field.type === 'textarea'"
          :value="product[key]"
          @change="changeField($event, key)"
      >
        {{product[key]}}
      </v-textarea>
      <v-select
          v-else-if="field.type === 'select'"
          @change="changeField($event, key)"
      >
        <option
            v-for="option in field.values"
            :value="option.value"
            :selected="option.value === +product[key]"
        >
          {{option.text}}
        </option>
      </v-select>
      <v-input
          v-else
          :value="product[key]"
          :type="field.isNumber ? 'number' : 'text'"
          @change="changeField($event, key)"
      />
    </div>
    <hr class="mt-5 mb-3">
    <div class="main-title">Параметры</div>
    <div
        v-for="param in product.params"
        class="mb-3 row"
    >
      <div class="col-5">
        <v-select disabled="true">
          <option v-for="dp in defaultParams" :value="dp.id" :selected="dp.id === param.param_id">{{dp.param}}</option>
        </v-select>
      </div>
      <div class="col-5">
        <v-input :value="param.value" @change="changeParameter(param.id, $event.target.value)"/>
      </div>
      <div class="col-2">
        <v-button class="btn-danger" @click="removeProductParam(param.id)">X</v-button>
      </div>
    </div>
    <v-select @change="addProductParam($event.target.value)">
      <option>Добавить параметр</option>
      <option v-for="dp in defaultParams" :value="dp.id">{{dp.param}}</option>
    </v-select>
    <div class="d-grid mt-5">
      <v-button @click="$emit('update:show', false)">Сохранить</v-button>
    </div>
  </v-modal>
</template>

<script>
import VModal from "@/components/UI/VModal";
import VInput from "@/components/UI/VInput";
import VSelect from "@/components/UI/VSelect";
import VTextarea from "@/components/UI/VTextarea";
import VButton from "@/components/UI/VButton";
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import {Navigation} from 'swiper/modules';
import axios from "axios";
export default {
  components: {VButton, VSelect, VInput, VModal, VTextarea, Swiper, SwiperSlide},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object,
      required: true
    }
  },
  methods: {
    changeField(event, key) {
      if(this.formFields[key].required === true && event.target.value === '') return event.target.focus()
      this.$store.commit('changeProductParam', {
        product: this.product,
        column: key,
        value: event.target.value
      })
    },
    triggerInput() {
      const input = document.getElementById('image-input');
      input.click();
    },
    removeImage(id) {
      this.$store.commit('removeImage', {
        id: id,
        product: this.product
      })
    },
    addImage(event) {
      this.$store.commit('addImage', {
        files: event.target.files,
        product: this.product
      })
    },
    changeParameter(id, value) {
      this.$store.commit('changeParameter', {
        id: id,
        value: value,
        product: this.product
      });
    },
    removeProductParam(id) {
      this.$store.commit('removeProductParam', {
        id: id,
        product: this.product
      });
    },
    addProductParam(value) {
      this.$store.commit('addProductParam', {
        value: value,
        product: this.product
      });
    }
  },
  data() {
    return {
      formFields: {
        name: {
          name: 'Название товара',
          type: 'input',
          required: true,
        },
        type: {
          name: 'Раздел',
          type: 'select',
          values: [
            {value: 1, text: 'Ламинат'},
            {value: 2, text: 'Кварцвинил'},
            {value: 3, text: 'Линолеум'},
            {value: 4, text: 'Комплектующие'},
          ],
          required: true
        },
        description: {
          name: 'Описание',
          type: 'textarea',
          required: false
        },
        price: {
          name: 'Актуальная цена (м2)',
          type: 'input',
          isNumber: true,
          required: true
        },
        old_price: {
          name: 'Старая цена',
          type: 'input',
          isNumber: true,
          required: false
        },
        pack_price: {
          name: 'Цена за упаковку',
          type: 'input',
          isNumber: true,
          required: false
        },
        pack_old_price: {
          name: 'Старая цена за упаковку',
          type: 'input',
          isNumber: true,
          required: false
        },
        pack_square: {
          name: 'Площадь в упаковке',
          type: 'input',
          required: false
        },
        sale: {
          name: 'Скидка',
          type: 'input',
          isNumber: true,
          required: false
        },
        folder: {
          name: 'Категория',
          type: 'select',
          values: [
            {value: 0, text: 'Без категории'},
            {value: 1, text: 'Скидки'},
            {value: 2, text: 'Популярные'},
            {value: 3, text: 'Новинки'},
          ],
          required: false
        }
      },
      defaultParams: []
    }
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
  async mounted() {
    const result = await axios.post(
        this.$store.state.params.apiURL,
        {
          action: 'get-default-params'
        }
    );
    this.defaultParams = result.data;
  },
  watch: {
    async product() {
      if(this.product.params !== undefined) return;

      const result = await axios.post(
          this.$store.state.params.apiURL,
          {
            action: 'get-product-params',
            id: this.product.id
          }
      );
      console.log(result);
      this.product.params = result.data;
      console.log(this.product);

    }
  }
}
</script>

<style scoped>
  .edit-product-image{
    width: 100%;
    padding: 15px;
    height: 500px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #EFEFEF;
    display: flex;
    justify-content: flex-end;
  }
</style>