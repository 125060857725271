<template>
  <div
      class="dialog"
      :class="size"
      v-show="show"
      @click="closeModal"
  >
    <div class="dialog-content" @click.stop>
      <div class="btn_close" @click="closeModal"></div>
      <slot></slot>
    </div>
  </div>

</template>

<script>
export default {
  name: 'v-modal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false);
    }
  },
  watch: {
    show(value) {
      if(value === true) return document.body.classList.add("no-scroll");
      return document.body.classList.remove("no-scroll");
    }
  }
}
</script>

<style scoped>
  .dialog{
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    overflow-y: scroll;
    z-index: 10001;
  }
  .dialog-content{
    margin: 40px auto;
    background-color: #fff;
    min-height: 200px;
    width: 500px;
    padding: 40px 60px;
    border-radius: 20px;
    position: relative;
  }
  .dialog.lg .dialog-content{
    width: 700px;
  }
  .btn_close{
    position: absolute;
    right: 15px;
    top: 15px;
    width: 1.2em;
    height: 1.2em;
    opacity: 0.6;
    transition: 0.3s;
    cursor: pointer;
    background: transparent url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e) center/1.2em auto no-repeat;
  }
  .btn_close:hover{
    opacity: 1;
  }
</style>
<style>
.modal-title{
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}
.modal-descr{
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;
}
</style>