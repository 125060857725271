<template>
  <section class="about">
    <div class="container">
      <div class="row">
        <div class="col-md-6 order-2 order-md-1">
          <div class="image">
            <img src="img/slogan.svg" alt="">
          </div>
        </div>
        <div class="col-md-6 order-1 order-md-2">
          <div class="content">
            <div class="main-title">О компании</div>
            <text-lg>
              Компания «Топтыгинпол» предлагает широкий ассортимент напольных покрытий в Казани по выгодным ценам. Продукция имеет все необходимые сертификаты и соответствует стандартам качества. Профессиональная команда консультантов всегда поможет вам подобрать необходимый товар для вашего помещения.
              <br><br>
              При покупке товара мы осуществляем доставку до вашего подъезда. Команда профессиональных укладчиков всегда к вашим услугам.
            </text-lg>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {}


}
</script>

<style scoped>
.about .image{
  border-radius: 20px;
  min-height: 100%;
  background-size: cover;
  background-position: center;
  background-image: url(@/assets/img/about-bg.jpg);
  display: flex;
  justify-content: center;
  align-items: center;
}
.about .main-title{
  text-align: left;
}
.about .content{
  margin-left: 40px;
}

@media (max-width: 575.98px) {
  .about{
    padding-bottom: 60px;
  }
  .about .main-title{
    text-align: center;
  }
  .about .content{
    margin-left: 0;
  }
  .about .image{
    height: 250px;
    padding: 10px;
    margin-top: 20px;
  }
  .about .image img{
    width: 100%;
  }
}
</style>