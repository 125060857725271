<template>
  <v-form @formSent="formSent">Отправить</v-form>
  <v-modal v-model:show="isModal">
    <div class="modal-title">Спасибо за заявку!</div>
    <div class="modal-descr">Мы свяжемся с Вами в ближайшее время!</div>
  </v-modal>
</template>

<script>
import VForm from "@/components/UI/VForm";

export default {
  components: {VForm},
  data() {
    return {
      isModal: false
    }
  },
  methods: {
    formSent(){
      this.isModal = true;
      this.$emit('formSent', true);
    }
  }
}
</script>

<style scoped>

</style>