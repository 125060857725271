<template>
  <div class="gallery" v-show="show">
    <swiper
        :modules="modules"
        :slides-per-view="1"
        :space-between="30"
        :loop="true"
        navigation
        class="swiper"
    >
      <swiper-slide v-for="image in images">
        <div class="image" :style="{backgroundImage: `url(${image})`}"></div>
      </swiper-slide>
    </swiper>
    <div class="close" @click="$emit('update:show', false)"></div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
export default {
  components: {Swiper, SwiperSlide},
  props: {
    images: {
      type: Array,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
  mounted() {
    console.log(this.swiper);
  }
}
</script>

<style scoped>
.swiper{
  min-height: 100%;
}
.gallery{
  position: fixed;
  z-index: 1001;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #000;
}
.image{
  min-height: 100%;
  height: 100vh;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.close{
  position: absolute;
  right: 20px;
  top: 20px;
  height: 25px;
  width: 25px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("@/assets/img/close.png");
  cursor: pointer;
  z-index: 1002;
}
.close:hover{
  opacity: 0.6;
}
</style>