<template>
  <a @click="$router.push(category.href)" class="category-item" :class="category.class" :style="{'background-image': 'url('+category.image+')'}">
    <div class="content">
      <div class="name">{{category.name}}</div>
      <div class="d-flex">
        <showmo href="#">Смотреть товары</showmo>
        <tag v-if="category.tag">{{category.tag}} товаров</tag>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  components: {},

  props: {
    category: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.category-item{
  display: flex;
  text-decoration: none;
  padding: 50px 60px;
  border-radius: 20px;
  background-size: 110%;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 30px;
  align-items: flex-end;
  transition: 0.5s;
  cursor: pointer;
  height: 440px;
}
.category-item:hover{
  background-size: 115%;
}
.category-item .name{
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 5px;
}
.category-item.lg{
  height: 630px;
}
.category-item.sm{
  height: 250px;
}
.category-item .showmo{
  margin-right: 30px;
}

@media(max-width: 575.98px) {
  .category-item{
    background-size: cover;
    background-position: bottom center;
    padding: 30px;
    height: 230px;
  }
  .category-item.lg{
    height: 430px;
  }
  .category-item.sm{
    height: 190px;
  }
  .category-item .name{
    font-size: 26px;
    margin-bottom: 10px;
  }

}
</style>