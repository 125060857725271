<template>
  <section>
    <div class="container">
      <div class="asking-form">
        <div class="row">
          <div class="col-md-6">
            <div class="content">
              <div class="main-title left">Не можете подобрать подходящий пол?</div>
              <div class="text-md">Заполните форму и получите бесплатную консультацию</div>
              <callback-form>Проконсультироваться</callback-form>
            </div>
          </div>
          <div class="col-md-6">
            <div class="image"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CallbackForm from "@/components/Layout/CallbackForm";
import VModal from "@/components/UI/VModal";
export default {
  components: {VModal, CallbackForm},

  methods: {

  }


}
</script>

<style scoped>
.asking-form{
  background-color: #F5F5F5;
  border-radius: 20px;
  overflow: hidden;
}
.asking-form .content{
  padding: 50px 120px;
}
.asking-form .main-title{
  font-size: 26px;
  margin-bottom: 30px;
}
.asking-form .text-md{
  margin-bottom: 30px;
}
.asking-form .image{
  min-height: 100%;
  background-image: url(@/assets/img/asking-form.jpg);
  background-size: cover;
  background-position: center;
}

@media(max-width: 575.98px) {
  .asking-form .content{
    padding: 45px;
  }
  .asking-form .image{
    height: 300px;
  }
}
</style>