<template>
  <textarea rows="30" class="form-control"></textarea>
</template>

<script>
export default {

}
</script>

<style scoped>
  .form-control{
    height: 120px;
  }
</style>