<template>
  <section class="categories">
    <div class="container">
      <div v-if="title" class="main-title">{{ title }}</div>
      <div class="row categories-wrap">
        <category-item v-for="category in categories" :category="category"/>
      </div>
    </div>
  </section>
</template>

<script>
import CategoryItem from "@/components/Layout/CategoryItem";
import axios from "axios";
export default {
  components: {CategoryItem},
  props: {
    title: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      categories: [
        {name: 'Кварцвинил', type: 2, href: '/catalog/quartz-vinyl', image: require('@/assets/img/category3.jpg'), class: 'lg order-md-1 order-1'},
        {name: 'Комплектующие', type: 4, href: '/catalog/components', image: require('@/assets/img/category4.jpg'), class: 'sm order-md-2 order-4'},
        {name: 'Ламинат', type: 1, href: '/catalog/laminate', image: require('@/assets/img/category1.jpg'), class: 'order-md-3 order-2'},
        {name: 'Линолеум', type: 3, href: '/catalog/linoleum', image: require('@/assets/img/category2.jpg'), class: 'order-md-4 order-3'},
      ],
    }
  },
  methods: {
    async getCategoriesCounts() {
      let categories = this.categories;
      try {
        const response = await axios.post(
            this.$store.state.params.apiURL,
            {
              action: 'get-categories-counts'
            }
        );
        response.data.forEach(function (elem) {
          let category = categories.find((category) => category.type === elem.type);
          category.tag = elem.count;
        });
      } catch (e) {
        console.log(e);
      }
    }
  },
  mounted() {
    this.getCategoriesCounts();
  }
}
</script>

<style scoped>
.categories-wrap{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 940px;
}
.categories-wrap .category-item{
  margin-right: 15px;
  margin-left: 15px;
  width: 570px;
}

@media(max-width: 575.98px) {
  .categories-wrap{
    height: auto;
  }
  .categories-wrap .category-item{
    width: auto;
  }
}
</style>