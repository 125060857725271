<template>
  <div class="calc-input-wrap" :class="size === 'small' ? 'sm' : ''">
    <div class="icon minus" @click="$emit('updateCalcInput', 'minus', objKey)">–</div>
    <v-input-large v-if="size === 'large'" :value="value" @input="changeInput($event)" @keypress="isNumber($event)"/>
    <v-input v-else :value="value" @input="changeInput($event)" @keypress="isNumber($event)"/>
    <div class="icon plus" @click="$emit('updateCalcInput', 'plus', objKey)">+</div>
  </div>
</template>

<script>
import VInput from "@/components/UI/VInput";
export default {
  components: {VInput},
  props: {
    value: {
      type: Number
    },
    size: {
      type: String,
      default: "large"
    },
    objKey: {
      type: String,
      required: true
    }
  },
  methods: {
    changeInput(evt) {
      this.$emit('update:value', Number(evt.target.value));
    },
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;

      }
    }
  }
}
</script>

<style scoped>
.calc-input-wrap{
  position: relative;
}
.calc-input-wrap .form-control{
  padding: 10px 60px;
  text-align: center;
}
.calc-input-wrap.sm .form-control{
  font-size: 18px;
  padding: 5px 30px;
  font-weight: 500;
}
.calc-input-wrap .icon{
  color: #ced4da;
  font-weight: 700;
  font-size: 35px;
  line-height: 1;
  cursor: pointer;
  transition: 0.3s;
  top: 10px;
  position: absolute;
}
.calc-input-wrap.sm .icon{
  top: 5px;
}
.calc-input-wrap.sm .icon{
  font-size: 26px;
}
.calc-input-wrap .icon:hover{
  opacity: 0.7;
}
.calc-input-wrap .icon.plus{
  right: 20px;
}
.calc-input-wrap .icon.minus{
  left: 20px;
}
.calc-input-wrap.sm .icon.plus{
  right: 15px;
}
.calc-input-wrap.sm .icon.minus{
  left: 15px;
}

</style>