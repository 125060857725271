<template>
  <div class="menu-item" :key="param.id">
    <div class="item-head" @click="$emit('changeFilterStatus', param.id)">
      <div class="name">{{ param.param }}</div>
      <div class="caret"></div>
    </div>
    <div class="item-body" v-show="param.isActive">
      <div v-for="value in param.values" class="menu-param" :class="value.isActive ? 'active': ''" @click="$emit('checkFilterItem', param.id, value)">
        <div class="param-check"></div>
        <div class="param-name">{{ value.value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'catalog-filter-item',
  props: {
    param: {
      type: Object,
      required: true
    }
  },
  methods: {
    // checkFilterItem(param_id, value, target) {
    //   this.$emit('checkFilterItem', param_id, value);
    //   if (!target.classList.contains('menu-param')) target = target.parentNode;
    //   return (target.classList.contains('active')) ? target.classList.remove("active") : target.classList.add("active");
    // }
  }
}
</script>

<style scoped>
.menu-item .item-head{
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;
}
.menu-item .item-body{
  max-height: 300px;
  overflow-y: scroll;
}
.menu-item .name{
  font-weight: 700;
  font-size: 16px;
}
.menu-item .caret{
  width: 15px;
  height: 11px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(@/assets/img/menu-caret.svg);
}
.menu-item .price{
  width: 80px;
  margin-right: 15px;
  padding-left: 10px;
}
.menu-item .defis{
  width: 24px;
  height: 2px;
  background-color: #000;
  margin-right: 18px;
}
.menu-item{
  margin-bottom: 20px;
}
.menu-item:last-child{
  margin-bottom: 0;
}
.menu-param{
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.menu-item .menu-param:last-child{
  margin-bottom: 0;
}
.menu-item .param-check{
  height: 22px;
  width: 22px;
  border-radius: 5px;
  margin-right: 12px;
  background-color: #FFFFFF;
  border: 2px solid #E5E5E5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.menu-item  .menu-param.active .param-check:before{
  position: absolute;
  content: "✓";
  font-size: 16px;
}
.catalog-menu .param-name{
  font-size: 16px;
}

@media (max-width: 575.98px) {
  .menu-item .item-body{
    max-height: 200px;
  }
}
</style>