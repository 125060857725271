<template>
  <form @submit="sendForm($event)" v-if="display === 'row'">
    <v-input-large class="mb-2 form-name" placeholder="Имя" maxlength="20"></v-input-large>
    <v-input-large class="mb-2 form-phone" type="Number" maxlength="12" placeholder="Телефон"></v-input-large>
    <div class="d-grid">
      <v-button class="btn-lg btn-danger form-button"><slot></slot></v-button>
    </div>
  </form>
  <form @submit="sendForm($event)" class="form-btn" v-else>
    <v-input-large class="mb-2 form-name d-none" placeholder="Имя" maxlength="20"></v-input-large>
    <v-input-large type="Number" class="danger form-phone" maxlength="12" placeholder="Номер телефона"/>
    <v-button class="btn-lg btn-danger">Получить</v-button>
  </form>
</template>

<script>
import axios from "axios";
export default {
  props: {
    display: {
      type: String,
      default: 'row'
    },
    products: {
      type: Array,
      default: []
    }
  },
  methods: {
    async sendForm(event) {
      event.preventDefault();
      const form = event.target;
      const formName = form.querySelector('.form-name'),
          formPhone = form.querySelector('.form-phone');

      if(formPhone.value.length < 10) return formPhone.focus();

      try {
        const response = await axios.post(
            this.$store.state.params.apiURL,
            {
              action: 'send-form',
              form_name: formName.value,
              form_phone: formPhone.value,
              products: this.products
            }
        );
        if(response.data.ok === true) {
          formName.value = '';
          formPhone.value = '';
          this.$emit('formSent', true);
        }else{
          alert('Ошибка, попробуйте обновить страницу');
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>
.form-btn{
  display: flex;
  align-items: center;
}
.form-btn .form-control{
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.form-btn .btn{
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding: 10px 25px;
}
</style>