<template>
  <input class="form-control" :value="value" @input="changeNumber($event)">
</template>

<script>
export default {
  name: 'v-input',
  props: {
    value: [String, Number]
  },
  methods: {
    changeNumber(event) {
      this.$emit('update:value', event.target.value);
      if(event.target.maxLength > 0 && event.target.value.length > event.target.maxLength) event.target.value = event.target.value.slice(0, event.target.maxLength);
    }
  }
}
</script>

<style>
.form-control{
  height: 40px;
  border-width: 1.5px;
  font-size: 16px;
  border-radius: 20px;
  padding: 5px 20px;
}
.form-control.lg{
  height: 60px;
  font-size: 20px;
  border-width: 3px;
  border-radius: 40px;
  padding: 10px 40px;
}
.form-control.danger{
  border-color: #DD204F;
}

@media (max-width: 575.98px) {
  .form-control.lg{
    font-size: 18px;
    padding: 10px 25px;
  }
}
</style>