<template>
  <section class="get-catalog">
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <div class="main-title">Получить каталог напольных покрытий в WhatsApp</div>
          <text-lg>Введите номер телефона, и мы вышлем Вам каталог топовых напольных покрытий на WhatsApp</text-lg>
          <v-form @formSent="formSent" :display="'inline'">Получить</v-form>
        </div>
        <div class="col-md-6 offset-md-1">
          <div class="image"></div>
        </div>
      </div>
    </div>
  </section>
  <v-modal v-model:show="isModal">
    <div class="modal-title">Спасибо за заявку!</div>
    <div class="modal-descr">Мы свяжемся с вами в WhatsApp в ближайшее время!</div>
  </v-modal>
</template>

<script>
import VForm from "@/components/UI/VForm";
export default {
  components: {VForm},
  data() {
    return {
      isModal: false
    }
  },
  methods: {
    formSent(show) {
      this.isModal = show;
    }
  }
}
</script>

<style scoped>
.get-catalog .main-title{
  text-align: left;
  margin-bottom: 50px;
}
.get-catalog .form-btn{
  margin-top: 50px;
}
.get-catalog .image{
  min-height: 100%;
  background-image: url(@/assets/img/getcatalog.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
}

@media (max-width: 575.98px) {
  .get-catalog .image{
    height: 200px;
    margin-top: 20px;
  }
}
</style>