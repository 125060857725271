<template>
  <section class="brands">
    <div class="container">
      <div class="main-title">Производители</div>
    </div>
    <div class="brands-container">

      <div v-for="n in 2" class="all-brands">
        <a
            @click="$router.push(brand.link)"
            v-for="brand in brands"
            class="brand-item"
            :style="{'background-image': 'url('+brand.image+')'}"></a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    brands: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.brands .brands-container{
  width: 7260px;
  display: flex;
  animation: marquee 30s infinite linear;
}
.brands .all-brands{
  display: flex;
  white-space: nowrap;
}
.brands .brand-item{
  height: 130px;
  min-width: 270px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 30px;
  margin-left: 30px;
  opacity: 0.8;
  transition: 0.3s;
  cursor: pointer;
}
.brands .brand-item:hover{
  opacity: 1;
}
@keyframes marquee{
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(-50%)
  }
}

@media (max-width: 575.98px) {
  .brands .brand-item{
    height: 90px;
    min-width: 160px;
    margin-right: 15px;
    margin-left: 15px;
  }
  .brands .brands-container{
    width: 4180px;
  }
}
</style>