let catalogFilters = window.localStorage.getItem('catalogFilters');
let activeSort = window.localStorage.getItem('activeSort');
let currentPage = window.localStorage.getItem('currentPage');
let currentCategory = window.localStorage.getItem('currentCategory');

export const catalogModule = {
    state: () => ({
        catalogFilters: catalogFilters ? JSON.parse(catalogFilters) : [],
        activeSort: activeSort ? activeSort : '',
        currentPage: currentPage ? +currentPage : 1,
        currentCategory: currentCategory ? +currentCategory : 1
    }),
    mutations: {
        changeCatalogFilters(state, catalogFilters) {
            state.catalogFilters = catalogFilters;
            window.localStorage.setItem('catalogFilters', JSON.stringify(state.catalogFilters));
        },
        changeActiveSort(state, activeSort) {
            state.activeSort = activeSort;
            window.localStorage.setItem('activeSort', state.activeSort);
        },
        changeCurrentPage(state, currentPage) {
            state.currentPage = currentPage;
            window.localStorage.setItem('currentPage', state.currentPage);
        },
        changeCurrentCategory(state, currentCategory) {
            state.currentCategory = currentCategory;
            window.localStorage.setItem('currentCategory', state.currentCategory);
        }
    }
}