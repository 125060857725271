<template>
  <section v-if="feedbacksList.length > 0">
    <div class="container">
      <div class="main-title">Отзывы покупателей</div>
      <div class="feedbacks-row">
        <swiper
            :slides-per-view="3"
            :space-between="30"
            :navigation="true"
            :modules="modules"
            :breakpoints="{ 300:{ slidesPerView: 1 }, 900:{ slidesPerView: 3 } }"
        >
          <swiper-slide v-for="feedback in feedbacksList">
            <feedback-item :feedback="feedback"/>
          </swiper-slide>
        </swiper>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-center mt-5">
          <showmo @click="$router.push('/feedbacks')" class="danger bold">Показать больше отзывов</showmo>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import {Navigation} from 'swiper/modules';
import FeedbackItem from "@/components/Layout/FeedbackItem";

export default {
  components: {
    Swiper,
    SwiperSlide,
    FeedbackItem
  },
  data() {
    return {
      feedbacksList: [
        {
          link: 'https://yandex.ru/web-maps/org/130599934801/reviews?reviews[publicId]=mxafp7pb130ebc44zjt255xxem&utm_source=review',
          name: 'Алекс Сафронов',
          date: '14.08.2023',
          text: 'Покупал ламинат в данном магазине. Все на высшем уровне. Проконсультировали, подсказали что и как сделать. Ребята мастера своего дела 👌🔥🤝 советую всем '
        },
        {
          link: 'https://yandex.ru/web-maps/org/130599934801/reviews?reviews[publicId]=y8dtuu8taywp61513ha0md9n8r&utm_source=review',
          name: 'Светлана С.',
          date: '17 марта',
          text: 'Огромный ассортимент , приемлемые цены , грамотный персонал . Консультанты подскажут ,помогут подобрать по потребностям и бюджету . Очень рекомендую данный магазин 👍 не первый раз покупаем там напольное покрытие и всегда очень рады покупкой'
        },
        {
          link: 'https://yandex.ru/web-maps/org/130599934801/reviews?reviews[publicId]=p9hbnxnv5eycn6pz4fkevkbuv4&utm_source=review',
          name: 'Лиана Зайцева',
          date: '3 августа',
          text: 'Объездила весь город и не нашла то что нужно.А в магазине Топтыгинпол,такой огромный выбор и цены приятно удивили.Все подробно объяснили и подарили подарок.Спасибо Вам большое.Рекомендую!!!!'
        },
        {
          link: 'https://yandex.ru/web-maps/org/130599934801/reviews?reviews[publicId]=yeehuf3gyq1czt3qa6k0bxfaw4&utm_source=review',
          name: 'Almaz K.',
          date: '7 июля',
          text: 'Лучший магазин напольных покрытий в городе, думаю даже в Поволжье! Расширенный ассортимент как цветовой гаммы, так и всевозможных дизайнов. Очень порадовали доступные цены! Отзывчивые менеджеры помогли все подобрать, организовали доставку и укладку кварцвинила. Рекомендую магазин, все сделали под ключ 👍 Обещали дополнитеную скидку для друзей)'
        },
        {
          link: 'https://yandex.ru/web-maps/org/130599934801/reviews?reviews[publicId]=ygzph7u2kuhtu7q6jzge14nuqm&utm_source=review',
          name: 'rus.11656',
          date: '16 августа',
          text: 'Искали кварцвинил по всему городу, нашли в топтыгинпол) большой выбор, проконсультировали и заказали! Качеством довольны'
        },
        {
          link: 'https://yandex.ru/web-maps/org/130599934801/reviews?reviews[publicId]=c1hkfw7ypjkzcqtw8juq85pr14&utm_source=review',
          name: 'Devil666',
          date: '19 сентября',
          text: 'Отличный магазин\n' +
              'Проконсультировали по всём напольным покрытиям.\n' +
              'Отношение сотрудников, направленно именно на клиента. Всё расскажут и подскажут.\n' +
              'Так же предложили возможность придержать ламинат у себя на складе, пока до делывали ремонт.'
        },
        {
          link: 'https://yandex.ru/web-maps/org/130599934801/reviews?reviews[publicId]=48v3frzbng8qq1ur5wpxrwm9cg&utm_source=review',
          name: 'леонид п.',
          date: '7 июня',
          text: '1) Отличный магазин.Выбор просто поразил,около 30 производителей разных фирм.Цены адекватные,не то что в других магазинах.\n' +
              'Рекомендую!!!!!'
        },
        {
          link: 'https://yandex.ru/web-maps/org/130599934801/reviews?reviews[publicId]=m7g3npav994hf9w9c8fnd10x1c&utm_source=review',
          name: 'Диляра Габдулхакова',
          date: '24 июня',
          text: 'Хороший выбор полуклммерческого линолеума. Работы проведены на уровне. Есть парковка. Рядом с домом. Цены не завышены. Все в наличии. Спасибо!'
        },
        {
          link: 'https://yandex.ru/web-maps/org/130599934801/reviews?reviews[publicId]=rxrh0dpkr9nud0kbje6gj03yu4&utm_source=review',
          name: 'Эльвира Х.',
          date: '4 мая',
          text: 'Отличный магазин!!! Помещение небольшое, но выбор огромный! Продавец общительный и доброжелательный! Всем рекомендую! Обязательно заедем ещё! 🤗👍🌹'
        },
        {
          link: 'https://yandex.ru/web-maps/org/130599934801/reviews?reviews[publicId]=vvgygb94vdqfnr2f2bwwrmuyz0&utm_source=review',
          name: 'Владимир П',
          date: '31 января',
          text: 'Покупал тут лемолеум , получилось дешевле чем в упрямдом, Доставка привезла рулоны, после того как раскатал в квартире оказалось что один рулон неприлично короче чем задумано, созвонился и через 1 день заменили без разговоров. Посоветовал соседям.'
        },
        {
          link: 'https://yandex.ru/web-maps/org/149667911668/reviews?reviews[publicId]=748qqxbux5evr6n38k6cyrxm48&utm_source=review',
          name: 'Azalina Ermolaeva',
          date: '21 марта',
          text: 'Отличный магазин! Большой выбор напольных покрытий. Сотрудники очень приятные, позитивные, подробно все объясняют, и подбирают исходя из предпочтений! Объездили всю Казань, и выбрали именно их! Цена качество💯Рекомендую однозначно👍🏻 Считаю лучшие в своём деле!'
        },
      ]
    }
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
}
</script>

<style scoped>
</style>