import {createRouter, createWebHistory} from "vue-router";
import Main from "@/pages/Main";
import Catalog from "@/pages/Catalog";
import Product from "@/pages/Product";
import CatalogList from "@/pages/CatalogList";
import Basket from "@/pages/Basket";
import Search from "@/pages/Search";
import About from "@/pages/About";
import Feedbacks from "@/pages/Feedbacks";
import Sales from "@/pages/Sales";
import Contacts from "@/pages/Contacts";
import Admin from "@/pages/Admin";

const routes = [
    {
        path: '/',
        component: Main,
        meta: {
            title: 'Топтыгинпол - напольные покрытия в Казани по выгодной цене - ТОПТЫГИНПОЛ Казань',
            description: 'Топтыгинпол - магазин напольных покрытий в Казани! Огромный выбор ламината, линолеума, кварцвинила. Наш адрес: г. Казань, Габишева 2. Тел: 8 (987) 422-59-94',
        }
    },
    {
        path: '/catalog',
        component: CatalogList
    },
    {
        path: '/catalog/:type',
        component: Catalog
    },
    {
        path: '/catalog/:type/:link',
        component: Product,
    },
    {
        path: '/basket',
        component: Basket
    },
    {
        path: '/search',
        component: Search
    },
    {
        path: '/about',
        component: About
    },
    {
        path: '/feedbacks',
        component: Feedbacks
    },
    {
        path: '/sales',
        component: Sales
    },
    {
        path: '/contacts',
        component: Contacts
    },
    {
        path: '/admin/renat',
        component: Admin
    }


]
const router = createRouter({

    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {left: 0, top: 0}
    }
})

export default router;