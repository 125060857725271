<template>
  <v-modal size="lg" @update:show="$emit('update:show', false)" :show="show">
    <div class="main-title">Редактирование акции</div>
    <div class="mb-3">
      <div class="sale-img" :style="saleItem.img_url !== '' ? `background-image: url(${$store.state.params.salesURL}/${saleItem.img_url})` : ''"></div>
      <input type="file" class="d-none" id="sale-image-input" name="image" accept="image/png, image/jpeg" @change="addImage($event)">
      <div class="d-grid">
        <v-button class="btn-danger mt-2" @click="triggerInput">Изменить изображение</v-button>
      </div>
    </div>
    <div class="mb-3">
      <div class="form-label">Название</div>
      <v-input
          placeholder="Название"
          :value="saleItem.name"
          @change="changeSale($event, 'name')"
      />
    </div>
    <div class="mb-3">
      <div class="form-label">Дата окончания</div>
      <v-input
          placeholder="Дата окончания"
          :value="saleItem.deadline"
          @change="changeSale($event, 'deadline')"
      />
    </div>
    <div class="d-grid mt-5">
      <v-button @click="$emit('update:show', false)">Сохранить</v-button>
    </div>
  </v-modal>
</template>

<script>
import VModal from "@/components/UI/VModal";
import VButton from "@/components/UI/VButton";
export default {
  components: {VButton, VModal},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    saleItem: {
      type: Object,
      required: true
    }
  },
  methods: {
    changeSale(event, column) {
      this.$store.commit('changeSale', {
        sale: this.saleItem,
        column: column,
        value: event.target.value
      });
    },
    triggerInput() {
      document.getElementById('sale-image-input').click();
    },
    addImage(event) {
      this.$store.commit('addSaleImage', {
        file: event.target.files[0],
        sale: this.saleItem
      })
    }
  }
}
</script>

<style scoped>
  .sale-img{
    width: 100%;
    height: 400px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #EFEFEF;
  }
</style>