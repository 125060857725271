<template>
  <div class="text-md">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'text-md'
}
</script>

<style scoped>
.text-md{
  font-size: 18px;
}
</style>