import VButton from "@/components/UI/VButton";
import Tag from "@/components/UI/Tag";
import Showmo from "@/components/UI/Showmo";
import VInput from "@/components/UI/VInput";
import TextLarge from "@/components/UI/TextLarge";
import TextMiddle from "@/components/UI/TextMiddle";
import DropdownButton from "@/components/UI/DropdownButton";
import CatalogFilterItem from "@/components/UI/CatalogFilterItem";
import BreadCrumbs from "@/components/UI/BreadCrumbs";
import VInputLarge from "@/components/UI/VInputLarge";
import VModal from "@/components/UI/VModal";

export default[
    VButton,
    Tag,
    Showmo,
    VInput,
    VInputLarge,
    TextLarge,
    TextMiddle,
    DropdownButton,
    CatalogFilterItem,
    BreadCrumbs,
    VModal
]