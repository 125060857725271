const auth = window.localStorage.getItem('auth');

export const authModule = {
    state: () => ({
        auth: !!auth,
    }),
    mutations: {
        setAuth() {
            window.localStorage.setItem('auth', 'active');
        },
        removeAuth() {
            window.localStorage.removeItem('auth');
        }
    },
    getters: {
        isAuth: () => {
            return !!window.localStorage.getItem('auth');
        }
    }
}