<template>
  <sales-list/>
  <Contacts/>
</template>

<script>
import SalesList from "@/components/Layout/SalesList";
import Contacts from "@/components/Layout/Contacts";
export default {
  components: {Contacts, SalesList}
}
</script>

<style scoped>

</style>