<template>
  <select class="form-control" @change="(event) => console.log(event)">
    <slot></slot>
  </select>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>