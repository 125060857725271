<template>
  <section>
    <div class="container" v-if="$store.state.basket.basket.length <= 0">
      <div class="row">
        <div class="col-12 d-flex flex-column align-items-center marg-y">
          <not-found class="mb-5">Корзина пуста</not-found>
          <v-button @click="$router.push('/catalog')" class="btn-lg">Перейти на главную</v-button>
        </div>
      </div>
    </div>
    <div class="container" v-else>
      <div class="main-title">Оформление заказа</div>
      <div class="row">
        <div class="col-md-8">
          <div class="basket-wrap">
            <div class="order-title">Корзина</div>
            <div class="product-item" v-for="product in $store.state.basket.basket">
              <div class="image" :style="{'background-image': 'url('+product.images[0]+')'}"></div>
              <div class="content">
                <div class="name">{{product.name}}</div>
                <div class="actions">
                  <div class="action-item-wrap order-md-1 order-3">
                    <div class="action-param">Кол-во упаковок</div>
                    <calc-input :size="'small'" v-model:value="product.packs" :objKey="product.id" @updateCalcInput="updateCalcInput"/>
                  </div>
                  <div class="action-item-wrap order-md-2 order-1">
                    <div class="action-param">Площадь</div>
                    <div class="action-value">{{product.totalSquare}} м²</div>
                  </div>
                  <div class="action-item-wrap order-md-3 order-2">
                    <div class="action-param">Стоимость</div>
                    <div class="action-value">{{product.totalPrice}} ₽</div>
                  </div>
                  <div class="action-item-wrap d-flex flex-column justify-content-end order-md-4 order-4">
                    <div class="action-param">Действие</div>
                    <v-button @click="$store.commit('removeFromBasket', product.id)" class="remove-btn">Удалить</v-button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="basket-wrap">
            <div class="order-title center">Стоимость: {{ $store.state.basket.basketTotalPrice }} ₽</div>
            <v-form @formSent="formSent" :products="$store.state.basket.basket">Оформить заказ</v-form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <v-modal :show="successModal" @update:show="closeSuccessModal">
    <div class="modal-title">Заказ успешно оформлен</div>
    <div class="modal-descr">Мы свяжемся с вами в ближайшее время</div>
    <div class="d-flex justify-content-center">
      <v-button @click="$router.push('/')">Вернуться на главную</v-button>
    </div>

  </v-modal>
  <v-modal v-model:show="errorModal">
    <div class="modal-title">Произошла ошибка</div>
    <div class="modal-descr">Попробуйте обновить страницу, либо свяжитесь по телефону <a :href="$store.state.contacts.phone_link_1">{{$store.state.contacts.phone_1}}</a></div>
  </v-modal>
</template>

<script>
import CallbackForm from "@/components/Layout/CallbackForm";
import NotFound from "@/components/UI/NotFoundNotice";
import CalcInput from "@/components/UI/CalcInput";
import VForm from "@/components/UI/VForm";

export default {
  components: {VForm, CalcInput, NotFound, CallbackForm},
  data() {
    return {
      successModal: false,
      errorModal: false
    }
  },
  methods: {
    updateCalcInput(event, product_id){
      let product = this.$store.state.basket.basket.find(product => product.id === product_id);
      console.log(product);
      if(event === 'plus') product.packs++;
      else if(product.packs > 1) product.packs--;
      this.$store.commit('updateBasketItemCount', product);
    },
    formSent(status){
      status ? this.successModal = true : this.errorModal = true;
    },
    closeSuccessModal(show) {
      this.successModal = show;
      this.$store.state.basket.basket = [];
      this.$store.commit('setProductTotalPrice');
      this.$router.push('/');
    }
  },
  mounted() {
    console.log(this.$store.state.basket.basket);
  },
}
</script>

<style scoped>
.basket-wrap{
  background-color: #F4F4F4;
  padding: 30px;
  border-radius: 20px;
}
.order-title{
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 30px;
}
.order-title.center{
  text-align: center;
}

.product-item{
  padding: 20px 0;
  border-top: 1.5px solid #ced4da;
  display: flex;
}
.product-item .image{
  height: 130px;
  width: 130px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  margin-right: 20px;
}
.product-item .name{
  font-size: 18px;
  font-weight: 500;
}
.product-item .content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 79%;
}
.product-item .actions{
  display: flex;
  justify-content: space-between;
}
.product-item .action-item-wrap{
  width: 130px;
}
.product-item .action-param{
  color: #A5A5A5;
  font-size: 14px;
  text-align: center;
}
.product-item .action-value{
  font-size: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-align: center;
}


@media(max-width: 575.98px) {
  .basket-wrap{
    margin-bottom: 30px;
  }
  .product-item .image{
    height: 90px;
    min-width: 90px;
    margin-right: 10px;
  }
  .product-item .actions{
    flex-wrap: wrap;
  }
  .product-item .name{
    margin-bottom: 20px;
  }
  .product-item .action-param{
    text-align: left;
  }
  .product-item .action-value{
    text-align: left;
    justify-content: flex-start;
  }
  .product-item .calc-input-wrap{
    max-width: 110px;
  }
  .product-item .remove-btn{
    max-width: 110px;
    padding: 5px;
  }
}

</style>