<template>
  <section class="specials" v-if="actualOffers.length > 0">
    <div class="container">
      <div class="main-title">Актуальные предложения</div>
      <div class="row">
        <div class="col-12 d-flex justify-content-center specials-navbar">
          <v-button
              v-for="offer in actualOffers"
              :class="{'btn-danger': offer.isActive,  'btn-outline-danger': !offer.isActive}"
              @click="$emit('changeOfferStatus', offer)"
          >
            {{ offer.name }}
          </v-button>
          <v-button @click="$router.push('/catalog/laminate')" class="btn-outline-danger">Все товары</v-button>
        </div>

        <div v-show="offer.isActive" class="specials-wrap" v-for="offer in actualOffers">
          <products-slider :products="offer.products"></products-slider>
        </div>
      </div>


    </div>
  </section>
</template>

<script>
import ProductsSlider from "@/components/Layout/ProductsSlider";

export default {
  components: {
    ProductsSlider,
  },
  props: {
    actualOffers: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped>
.specials .specials-navbar {
  margin-bottom: 50px;
}
.specials-navbar .btn {
  min-width: 160px;
  margin-right: 7.5px;
  margin-left: 7.5px;
}

@media(max-width: 575.98px) {
  .specials .specials-navbar{
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  .specials-navbar .btn{
    margin-bottom: 10px;
    font-size: 14px;
  }
}
</style>