<template>
  <div class="tag"><slot></slot></div>
</template>

<script>
export default {
  name: 'tag'
}
</script>

<style>
.tags-wrap{
  display: flex;
  justify-content: flex-end;
}
.tag{
  font-size: 16px;
  font-weight: 600;
  padding: 5px 25px;
  color: #fff;
  border-radius: 20px;
  background: rgba(196, 206, 212, 0.75);
}
.tag.sm{
  font-size: 14px;
  padding: 4px 15px;
}
.tag.danger{
  background: #DD204F;
}

@media(max-width: 575.98px) {
  .tag{
    font-size: 14px;
    padding: 5px 10px;
  }
}
</style>