<template>
  <section v-if="tabs.length > 0" class="product-tabs">
    <div class="container">
      <div class="params-navbar">
        <div
            v-for="tab in tabs"
            class="navbar-item"
            :class="tab.isActive ? 'active' : ''"
            @click="$emit('setTab', tab)"
        >
          {{ tab.name }}
        </div>
      </div>
      <div v-for="tab in tabs" v-show="tab.isActive" class="params-value">
        <div v-if="tab.type === Array" class="row">
          <div v-for="param in tab.content" class="col-md-6 pe-0">
            <div class="param-item">
              <div class="param-value name">{{ param.name }}</div>
              <div class="param-value">{{ param.value }}</div>
            </div>
          </div>
        </div>
        <div v-else-if="tab.type === String">
          <text-md>{{tab.content}}</text-md>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.params-navbar{
  display: flex;
  justify-content: center;
  border-bottom: 3px solid #A5A5A5;
  margin-bottom: 20px;
}
.params-navbar .navbar-item{
  font-size: 18px;
  font-weight: 700;
  margin-right: 45px;
  margin-left: 45px;
  border-bottom: 3px solid transparent;
  margin-bottom: -3px;
  cursor: pointer;
  transition: 0.3s;
  padding: 15px 0;
  color: #A5A5A5;
}
.params-navbar .navbar-item.active, .params-navbar .navbar-item:hover{
  color: #3A3A3A;
  border-color: #3A3A3A;
}

.params-value .param-item{
  padding: 15px 20px;
  display: flex;
  border-bottom: 2px solid #F5F5F5;
  transition: 0.3s;
}
.params-value .param-item.grey{
  background-color: #F5F5F5;
}
.params-value .param-item:hover{
  background-color: #F5F5F5;
}
.params-value .param-value{
  font-size: 16px;
  width: 50%;
}
.params-value .param-value.name{
  font-weight: 700;
}

@media(max-width: 575.98px) {
  .params-navbar .navbar-item{
    margin-right: 10px;
    margin-left: 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
  }
  .params-value .param-value.name{
    margin-right: 10px;
  }
  .params-value .param-value{
    font-size: 14px;
  }
}
</style>